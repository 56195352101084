import { INewScheduleTemplate } from '@smena.wfm/api';
import { createEntityAdapter, createSlice, Dictionary, EntityId } from '@reduxjs/toolkit';

export const scheduleTemplatesAdapter = createEntityAdapter<INewScheduleTemplate>({
  selectId: scheduleTemplate => scheduleTemplate.id,
});

export interface IScheduleTemplatesState {
  ids: EntityId[];
  entities: Dictionary<INewScheduleTemplate>;
}

const scheduleTemplatesSlice = createSlice({
  name: 'scheduleTemplates',
  initialState: scheduleTemplatesAdapter.getInitialState(),
  reducers: {
    create: scheduleTemplatesAdapter.setMany,
    update: scheduleTemplatesAdapter.upsertMany,
    remove: scheduleTemplatesAdapter.removeMany,
    clear: scheduleTemplatesAdapter.removeAll,
  },
});

export default scheduleTemplatesSlice.reducer;

export const { create, update, remove, clear } = scheduleTemplatesSlice.actions;
