import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectCurrentUserId } from '~/redux/selectors/authSelectors';
import { ReactComponent as ArrowDownSvg } from '~/assets/arrow-down.svg';
import { ReactComponent as CloseSvg } from '~/assets/close.svg';
import Api, { EShiftType, INewShift, EShiftStatus } from '@smena.wfm/api';
import moment, { Moment } from 'moment';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import convertToLocal from '~/helpers/convertToLocal';
import { useAppSelector } from '~/hooks/redux';
import { selectWorkingShift } from '~/redux/selectors/workingShiftsSelectors';
import { selectCurrentShift, selectDay } from '~/redux/selectors/stateSelectors';
import { getLocalMomentTime } from '~/helpers/convertToUnix';
import { FORMAT_MOMENT, COLORS, ALERT_TYPE, ALERT } from '~/helpers/constants';
import convertTimeHM from '~/helpers/convertTimeHM';
import Button from '~/components/form/buttons/Button';
import getTextOrToday from '~/helpers/getTextOrToday';
import { selectShifts } from '~/redux/selectors/shiftsSelectors';
import { showAlert } from '~/redux/modules/stateModule';

export const UserMenuShift = () => {
  const dispatch = useDispatch();
  const userId = useSelector(selectCurrentUserId);
  const currentDay = useAppSelector(selectDay);
  const companyOptions = useSelector(selectCompanyOptions);
  const currentShiftId = useAppSelector(selectCurrentShift);
  const shifts = useAppSelector(selectShifts);

  const [openShift, setOpenShift] = React.useState(false);
  const [timeStart, setTimeStart] = React.useState<string | undefined>('');
  const [timeEnd, setTimeEnd] = React.useState<string | undefined>('');
  const [timeText, setTimeText] = React.useState<string | undefined>('');
  const [dateText, setDateText] = React.useState<string | undefined>('');

  const [shift, setShift] = React.useState<INewShift | undefined>(undefined);

  React.useEffect(() => {
    setShift(shifts.find(item => item.id === currentShiftId?.id));
    setOpenShift(false);
  }, [userId, currentShiftId, shifts]);

  const workShift = useAppSelector(selectWorkingShift(shift?.working_shift_id));
  const [actualEnd, setActualEnd] = React.useState<Moment>(
    moment(
      `${moment().format(FORMAT_MOMENT.DASH_YYYYMMDD)} ${convertToLocal(
        workShift?.end_time,
        false,
        companyOptions.time_zone,
      )}`,
    ),
  );

  const actual = getLocalMomentTime(shift?.start_time, companyOptions.time_zone);
  const actualTimeRight = convertTimeHM(moment(actual).diff(moment()), true).text;
  const actualTimeLeft = convertTimeHM(moment(moment()).diff(actual), true).text;
  const actualTimeLeftEnd = convertTimeHM(moment(actualEnd).diff(moment()), true).text;

  const setTimeMessage = (message: string, actual: string, time_zone: number, toNow?: boolean) =>
    toNow ? setTimeText(`${message} ${actual}.`) : setTimeText(`${message} ${actual}.`);

  React.useEffect(() => {
    if (shift?.start_time) {
      setDateText(
        getTextOrToday(
          shift?.start_time,
          actual.format('DD MMM YYYY'),
          companyOptions.time_zone,
          true,
        ),
      );
    }

    setActualEnd(
      currentDay === actual.format(FORMAT_MOMENT.DASH_YYYYMMDD)
        ? getLocalMomentTime(shift?.end_time, companyOptions.time_zone)
        : getLocalMomentTime(shift?.start_time, companyOptions.time_zone).add(1, 'day'),
    );
  }, [currentDay, shift, workShift, companyOptions]);

  React.useEffect(() => {
    if (userId) {
      setTimeStart(
        shift?.check_in_time
          ? getLocalMomentTime(shift?.check_in_time, companyOptions.time_zone).format(
              FORMAT_MOMENT.HHMM,
            )
          : getLocalMomentTime(shift?.start_time, companyOptions.time_zone).format(
              FORMAT_MOMENT.HHMM,
            ),
      );
      setTimeEnd(
        shift?.check_out_time
          ? getLocalMomentTime(shift?.check_out_time, companyOptions.time_zone).format(
              FORMAT_MOMENT.HHMM,
            )
          : getLocalMomentTime(shift?.end_time, companyOptions.time_zone).format(
              FORMAT_MOMENT.HHMM,
            ),
      );
    }
    const timeIsBefore = moment(actual).isBefore(actualEnd, 'minute');
    if (timeIsBefore) {
      if (moment().isAfter(actual, 'minute') && !shift?.check_in_time && !shift?.check_out_time) {
        setTimeMessage('Вы опаздываете на', actualTimeLeft, companyOptions.time_zone, true);
      } else if (shift?.check_in_time && !shift?.check_out_time) {
        setTimeMessage('До конца', actualTimeLeftEnd, companyOptions.time_zone, false);
      } else {
        setTimeMessage('До начала', actualTimeRight, companyOptions.time_zone, false);
      }
    } else {
      if (moment().isAfter(actual, 'minute') && !shift?.check_in_time && !shift?.check_out_time) {
        setTimeMessage('Вы опаздываете на', actualTimeLeft, companyOptions.time_zone, true);
      } else if (shift?.check_in_time && !shift?.check_out_time) {
        setTimeMessage('До конца', actualTimeLeftEnd, companyOptions.time_zone, false);
      } else {
        setTimeMessage('До начала', actualTimeRight, companyOptions.time_zone, false);
      }
    }
  }, [userId, companyOptions, workShift, shift, actual, actualEnd]);

  const openPopupSmena = () => {
    if (
      shift &&
      shift.type !== EShiftType.CANCELED &&
      (shift?.type === EShiftType.ACCEPTED || shift?.type !== EShiftType.ADDITIONAL) &&
      !shift.check_out_time
    ) {
      setOpenShift(!openShift);
    }
  };

  const popupSmenaClose = () => {
    if (shift) {
      setOpenShift(!openShift);
    }
  };

  const handleCheckIn = React.useCallback(async () => {
    if (shift?.id) {
      await Api.checkIn({
        shiftId: shift?.id,
        location: { lat: 0, lng: 0 },
      });
      setOpenShift(false);
    }
  }, [shift]);

  const handleCheckOut = React.useCallback(() => {
    if (shift?.id) {
      setOpenShift(false);
      Api.getCurrentTask().then(response => {
        if (response.kind === 'ok') {
          if (response.data) {
            const data = {
              title: 'Завершите задачу',
              description: 'Вы не можете выйти, так как у вас осталась незавершенная задача.',
              name: ALERT.END_SHIFT,
              type: ALERT_TYPE.CLEAR,
            };

            dispatch(showAlert(data));
          } else {
            const data = {
              title: 'Закончите смену',
              description: 'Вы не можете выйти, так как у вас не закончена смена. Закончить смену?',
              name: ALERT.END_SHIFT,
              type: ALERT_TYPE.FINISH,
              data: {
                shiftId: shift?.id,
              },
            };

            dispatch(showAlert(data));
          }
        }
      });
    }
  }, [shift]);

  return (
    <div className="user-menu__item user-menu__item--smena">
      <button
        className={`user-menu__smena ${
          shift?.check_in_time && !shift?.check_out_time
            ? 'user-menu__smena--active'
            : shift?.check_in_time && shift?.check_out_time
            ? 'user-menu__smena--active-end'
            : ''
        }`}
        onClick={openPopupSmena}
      >
        {shift &&
        shift.type !== EShiftType.CANCELED &&
        (shift.type !== EShiftType.ACCEPTED || shift?.status !== EShiftStatus.DONE) ? (
          <div className="user-menu__block-date-time">
            <p className="user-menu__time">
              Смена
              <p
                className={
                  shift?.check_in_time
                    ? 'user-menu__time-start user-menu__time-start_active'
                    : 'user-menu__time-start'
                }
              >
                {timeStart}
              </p>
              <p
                className={
                  shift?.check_out_time
                    ? 'user-menu__time-end user-menu__time-end_active'
                    : 'user-menu__time-end'
                }
              >
                {' '}
                – {timeEnd}
              </p>
            </p>
            <span className="user-menu__date">{dateText}</span>
          </div>
        ) : (
          <div className="user-menu__block-date-time">
            <p className="user-menu__time">Вы не на смене</p>
          </div>
        )}
        <ArrowDownSvg />
      </button>
      {openShift && (
        <div className="popup-smena">
          <div className="popup-smena__close" onClick={popupSmenaClose}>
            <CloseSvg />
          </div>
          <div className="popup-smena__container">
            <div className="popup-smena__title">Текущая смена</div>
            <div className="popup-smena__desc">{timeText}</div>
            <Button
              text={!shift?.check_in_time ? 'начать смену' : 'закончить смену'}
              onClick={!shift?.check_in_time ? handleCheckIn : handleCheckOut}
              background={COLORS.BLUE}
            />
          </div>
        </div>
      )}
    </div>
  );
};
