import Api, {
  IApproveWorkRequestForm,
  IChangeStatusWorkRequestForm,
  ICreateWorkRequestForm,
  IReturnWorkRequestForm,
  IUpdateWorkRequestForm,
  IWorkRequest,
  IWorkRequestsListForm,
  WorkRequest,
} from '@smena.wfm/api';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  Dictionary,
  EntityId,
} from '@reduxjs/toolkit';
import store from '~/redux/configureStore';

export const workRequestsAdapter = createEntityAdapter<IWorkRequest>({
  selectId: workRequest => workRequest.id,
  sortComparer: (a, b) => b.id - a.id,
});

export interface IWorkRequestsState {
  ids: EntityId[];
  entities: Dictionary<IWorkRequest>;
  isLoading: boolean;
  total_count: number;
}

export const getWorkRequests = createAsyncThunk(
  'getWorkRequests',
  async (data: IWorkRequestsListForm) => {
    return await Api.getWorkRequestsList(data);
  },
);

export const createWorkRequestPos = createAsyncThunk(
  'createWorkRequestPos',
  async (input: ICreateWorkRequestForm) => {
    const response: WorkRequest = await Api.createWorkRequest(input);
    const data: WorkRequest = response;
    if (data.kind === 'ok') {
      store.dispatch({
        type: `workRequests/add`,
        payload: data.data,
      });
    }
    return response;
  },
);

export const cancelCloseWorkRequestPos = createAsyncThunk(
  'cancelWorkRequestPos',
  async (input: IChangeStatusWorkRequestForm) => {
    const response: WorkRequest = await Api.changeStatusWorkRequest(input);
    const data: WorkRequest = response;
    if (data.kind === 'ok') {
      store.dispatch({
        type: `workRequests/add`,
        payload: data.data,
      });
    }
    return response;
  },
);

export const returnWorkRequestPos = createAsyncThunk(
  'returnWorkRequestPos',
  async (input: IReturnWorkRequestForm) => {
    const response: WorkRequest = await Api.returnWorkRequest(input);
    const data: WorkRequest = response;

    if (data.kind === 'ok') {
      store.dispatch({
        type: `workRequests/update`,
        id: data.data.id,
        payload: data?.data,
      });
    }
    return response;
  },
);

export const approveWorkRequest = createAsyncThunk(
  'approveWorkRequest',
  async (input: IApproveWorkRequestForm) => {
    const response: WorkRequest = await Api.approveWorkRequest(input);
    const data: WorkRequest = response;

    if (data.kind === 'ok') {
      store.dispatch({
        type: `workRequests/update`,
        id: data.data.id,
        payload: data?.data,
      });
    }
    return response;
  },
);

export const editWorkRequestPos = createAsyncThunk(
  'editWorkRequestPos',
  async (input: IUpdateWorkRequestForm) => {
    const response: WorkRequest = await Api.updateWorkRequest(input);
    const data: WorkRequest = response;
    if (data.kind === 'ok') {
      store.dispatch({
        type: `workRequests/update`,
        id: data.data.id,
        payload: data.data,
      });
    }
    return response;
  },
);

const workRequestsSlice = createSlice({
  name: 'workRequests',
  initialState: workRequestsAdapter.getInitialState({
    isLoading: false,
    total_count: 0,
  }),
  reducers: {
    setAll: workRequestsAdapter.setAll,
    create: workRequestsAdapter.setMany,
    update: workRequestsAdapter.upsertMany,
    updateOne: workRequestsAdapter.updateOne,
    remove: workRequestsAdapter.removeMany,
    clear: workRequestsAdapter.removeAll,
    add: workRequestsAdapter.upsertOne,
  },
  extraReducers: builder => {
    builder.addCase(getWorkRequests.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getWorkRequests.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.kind === 'ok') {
        if (action.meta.arg.offset) {
          workRequestsAdapter.upsertMany(state, action.payload.data.data);
        } else {
          workRequestsAdapter.setAll(state, action.payload.data.data);
        }
        state.total_count = action.payload.data.total_count;
      }
    });
  },
});

export default workRequestsSlice.reducer;

export const { create, update, updateOne, clear, remove } = workRequestsSlice.actions;
