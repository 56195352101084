import { INewUser } from '@smena.wfm/api';
import React, { useMemo } from 'react';
import { ReactComponent as PencilSvg } from '~/assets/pencil.svg';
import { useDispatch, useSelector } from 'react-redux';
import { selectProfession } from '~/redux/selectors/professionsSelectors';
import { selectDepartmentsByIds } from '~/redux/selectors/departmentsSelectors';
import { selectUserRoleCanEdit } from '~/redux/selectors/authSelectors';
import { useAppSelector } from '~/hooks/redux';
import { selectRole } from '~/redux/selectors/rolesSelectors';
import { openPopup } from '~/redux/modules/popupModule';
import { POPUPS_NAME } from '~/components/PopupManager';

export const Row: React.FC<INewUser> = ({
  id,
  firstname,
  lastname,
  middlename,
  departments_ids,
  profession_id,
  login,
  category,
  role_id,
}) => {
  const dispatch = useDispatch();
  const profession = useSelector(selectProfession(profession_id));
  const departments = useSelector(selectDepartmentsByIds(departments_ids));
  const role = useSelector(selectRole(role_id));
  const userRoleCanEdit = useAppSelector(selectUserRoleCanEdit);
  const departmentsNames = departments.map(department => department.name).join(', ');

  const handleClickEdit = () => {
    dispatch(openPopup({ name: POPUPS_NAME.WORKER_POPUP, data: { userId: id } }));
  };

  const initials = useMemo(
    () => `${lastname.charAt(0).toUpperCase()}${firstname.charAt(0).toUpperCase()}`,
    [firstname, lastname],
  );
  const fio = useMemo(() => {
    if (!middlename) {
      middlename = '';
    }
    return `${lastname} ${firstname} ${middlename}`;
  }, [firstname, lastname, middlename]);

  const specialization = useMemo(
    () => `${profession?.name} ` + (category ? category + ' разряда' : ''),
    [profession, category],
  );

  return (
    <div className="list-table__row">
      <div className="list-table__col list-table__col_avatar">
        <span className="list-table__user-avatar user-avatar">{initials}</span>
      </div>
      <div className="list-table__col list-table__col_name">
        {fio}
        {userRoleCanEdit && (
          <button className="list-table__edit-btn" onClick={handleClickEdit}>
            <PencilSvg />
          </button>
        )}
      </div>
      <div className="list-table__col list-table__col_role">{role?.name}</div>
      <div className="list-table__col list-table__col_specialisation">{specialization}</div>
      <div className="list-table__col list-table__col_place">{departmentsNames}</div>
      <div className="list-table__col list-table__col_number">{login}</div>
    </div>
  );
};
