import * as React from 'react';
import TaskCarousel from '~/components/task-carousel/carousel';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import Calendar from './calendar';
import { EShiftType, INewShift, EShiftStatus } from '@smena.wfm/api';
import { Templates } from './templates';
import { selectCurrentUserId } from '~/redux/selectors/authSelectors';
import { selectDepartments } from '~/redux/selectors/departmentsSelectors';
import { getDayShifts } from '~/redux/modules/shiftsModule';
import { selectShifts } from '~/redux/selectors/shiftsSelectors';
import {
  selectDay,
  selectDepartmentId,
  selectIsSelectedToday,
  selectCurrentShift,
} from '~/redux/selectors/stateSelectors';
import { setDepartmentId, setWorkShiftId } from '~/redux/modules/stateModule';
import {
  selectCurrentWorkingShift,
  selectWorkingShifts,
} from '~/redux/selectors/workingShiftsSelectors';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import { useAppSelector } from '~/hooks/redux';
import { prepareDateServerTime } from '~/helpers/convertToUnix';
import { FORMAT_MOMENT, ICON_TYPE, BUTTON_TYPE, PLACEHOLDER } from '~/helpers/constants';
import { TasksNoTime } from '~/containers/tasks/TasksNoTime';
import { openPopup } from '~/redux/modules/popupModule';
import { POPUPS_NAME } from '~/components/PopupManager';
import TasksStatistic from '~/containers/tasks/TasksStatistic';
import { TaskForm } from '~/containers/tasks/TaskForm';
import SelectsField from '~/components/form/select/Select';
import { selectUser } from '~/redux/selectors/usersSelectors';
import Button from '~/components/form/buttons/Button';
import getTextOrToday from '~/helpers/getTextOrToday';
import { setWorkShiftIds } from '~/redux/modules/stateModule';
import { selectWorkShiftIds } from '~/redux/selectors/stateSelectors';
import { selectDepartmentIds } from '~/redux/selectors/stateSelectors';
import { setDepartmentIds } from '~/redux/modules/stateModule';
import { selectWorkShiftId } from '~/redux/selectors/stateSelectors';
import { COLORS } from '~/helpers/constants';
import useAuthRole from '~/hooks/useAuthRole';
import { WorkRequests } from './workRequests';
import ScreenBlock from '~/components/screen-block/screen-block';
import { titlesMap } from '~/utils/titles';
import ComboboxField from '~/components/form/select/DropDown';

const TasksScreen = () => {
  const dispatch = useDispatch();
  const { isSupervisor, isDepartment, isView } = useAuthRole();

  const companyOptions = useAppSelector(selectCompanyOptions);
  const isSelectedToday = useAppSelector(selectIsSelectedToday);
  const selectedDay = useAppSelector(selectDay);
  const workShiftId = useAppSelector(selectWorkShiftId);
  const workShiftIds = useAppSelector(selectWorkShiftIds);
  const currentWorkShift = useAppSelector(selectCurrentWorkingShift);
  const userId = useAppSelector(selectCurrentUserId);
  const currentShift = useAppSelector(selectCurrentShift);
  const departments = useAppSelector(selectDepartments);
  const departmentId = useAppSelector(selectDepartmentId);
  const departmentIds = useAppSelector(selectDepartmentIds);
  const currentUserId = useAppSelector(selectCurrentUserId);
  const user = useAppSelector(selectUser(currentUserId));
  const shifts = useAppSelector(selectShifts);
  const workingShiftsTypes = useAppSelector(selectWorkingShifts);
  const currentDay = useAppSelector(selectDay);
  const userDepartments = [...(user?.departments_ids || []), ...(user?.view_departments_ids || [])];

  const myWorkingShifts = React.useMemo(
    () => shifts.filter(shift => shift.user_id === currentUserId),
    [shifts, currentUserId],
  );

  React.useEffect(() => {
    if (user?.departments_ids && (!departmentId || !user.departments_ids.includes(departmentId))) {
      dispatch(setDepartmentId(user?.departments_ids[0]));
      dispatch(setDepartmentIds([user?.departments_ids[0]]));
    }
  }, [user]);

  React.useEffect(() => {
    if (isSupervisor) {
      if (
        myWorkingShifts[0]?.working_shift_id &&
        !workShiftIds.includes(workShiftId) &&
        workingShiftsTypes[0]?.id
      ) {
        dispatch(setWorkShiftId(myWorkingShifts[0]?.working_shift_id ?? workingShiftsTypes[0]?.id));
        dispatch(
          setWorkShiftIds([myWorkingShifts[0]?.working_shift_id ?? workingShiftsTypes[0]?.id]),
        );
      }
    } else {
      if (!workShiftId && !workShiftIds.includes(workShiftId) && workingShiftsTypes[0]?.id) {
        dispatch(setWorkShiftId(workingShiftsTypes[0]?.id));
        dispatch(setWorkShiftIds([workingShiftsTypes[0]?.id]));
      }
    }
  }, [myWorkingShifts, workingShiftsTypes[0]]);

  const preparedWorkingShifts: ISelectOption = {
    '0': 'Все смены',
  };

  const preparedDepartments: ISelectOption = {};

  const [shift, setShift] = React.useState<INewShift | undefined>(undefined);

  React.useEffect(() => {
    if (userId) {
      setShift(shifts.find(item => item.id === currentShift?.id));
    }
  }, [userId, currentShift, shifts]);

  const getBeginEndTime = () => {
    let startTime, continueTime;
    try {
      const timeFrom = currentWorkShift?.begin_time || moment().format(FORMAT_MOMENT.HHMM);
      const timeTo = currentWorkShift?.end_time || moment().format(FORMAT_MOMENT.HHMM);

      const [hours, minutes] = timeFrom.split(':');
      const [hoursTo, minutesTo] = timeTo.split(':');

      const dayStart = moment(selectedDay);
      let dayEnd = moment(selectedDay);

      const shiftBeginTz = moment(selectedDay + ' ' + currentWorkShift?.begin_time)
        .add(companyOptions.time_zone, 'hour')
        .format(FORMAT_MOMENT.HHMMSS);
      const shiftEndTz = moment(selectedDay + ' ' + currentWorkShift?.end_time)
        .add(companyOptions.time_zone, 'hour')
        .format(FORMAT_MOMENT.HHMMSS);

      const shiftBeginTime = moment(selectedDay + ' ' + shiftBeginTz);
      const shiftEndTime = moment(selectedDay + ' ' + shiftEndTz);

      if (currentWorkShift && shiftBeginTime.unix() >= shiftEndTime.unix()) {
        const taskBeginMinutes = parseInt(minutes) + parseInt(hours) * 60;
        const taskEndMinutes = parseInt(minutesTo) + parseInt(hoursTo) * 60;

        if (taskEndMinutes <= taskBeginMinutes) {
          dayEnd.add(1, 'day');
        } else {
          dayEnd = moment(dayStart);
        }
      }

      startTime = dayStart
        .add(+hours + companyOptions.time_zone, 'hours')
        .add(minutes, 'minutes')
        .unix();
      continueTime = dayEnd
        .add(+hoursTo + companyOptions.time_zone, 'hours')
        .add(minutesTo, 'minutes')
        .unix();
    } catch (e) {
      startTime = 0;
      continueTime = 0;
    }

    return { startTime, continueTime };
  };

  const { startTime, continueTime } = getBeginEndTime();
  const dayIsShift =
    (moment().unix() > startTime || moment().unix() < startTime) && moment().unix() < continueTime;

  React.useEffect(() => {
    const serverDatetime = prepareDateServerTime(selectedDay, companyOptions.time_zone);
    dispatch(getDayShifts(serverDatetime));
  }, [selectedDay]);

  const [tab, setTab] = React.useState(0);
  const [workRequestsTab, setWorkRequestsTab] = React.useState(0);

  const handelClick = (id: number) => {
    setTab(id);
  };
  const handelWorkRequestTabClick = (id: number) => {
    setWorkRequestsTab(id);
  };

  const handleCreateClick = React.useCallback(() => {
    dispatch(openPopup({ name: POPUPS_NAME.CREATE_TASK_POPUP, data: { type: tab } }));
  }, [dispatchEvent, tab]);

  const handleCreateWorkRequestClick = React.useCallback(() => {
    dispatch(openPopup({ name: POPUPS_NAME.CREATE_WORK_REQUEST_POPUP }));
  }, [dispatchEvent, tab]);

  workingShiftsTypes.forEach(item => (preparedWorkingShifts[item.id] = item.name));

  departments.forEach(department => {
    if (userDepartments.includes(department.id)) {
      return (preparedDepartments[department.id] = department.name);
    }
  });

  const prepareOptions = (options: ISelectOption) => {
    const array = Object.keys(options).map(key => {
      return { value: key, label: options[key] };
    });

    const empty = array.findIndex(elem => elem.value === '');

    if (empty !== -1) {
      array.splice(0, 0, array.splice(empty, 1)[0]);
    }

    return array;
  };

  const preparedWorkShiftIds: ISelectOption = {};
  const preparedDepartmentIds: ISelectOption = {};

  workShiftIds?.forEach(id => {
    preparedWorkShiftIds[id] = preparedWorkingShifts[id];
  });

  departmentIds?.forEach(id => {
    preparedDepartmentIds[id] = preparedDepartments[id];
  });

  const resetData = () => {
    dispatch(setDepartmentIds([departmentId]));
    dispatch(setWorkShiftIds([workShiftId]));
  };

  return (
    <div className="layout-screen">
      <h1 className="layout-screen__title">
        {getTextOrToday(
          selectedDay,
          moment(selectedDay).format(FORMAT_MOMENT.DDMMM),
          companyOptions.time_zone,
        )}
        <Calendar />
      </h1>
      <div className="layout-screen__content">
        <div className="layout-screen__selects">
          {companyOptions.use_schedule_template && (
            <SelectsField
              classNameWrapper="layout-screen__select"
              options={preparedWorkingShifts}
              // @ts-ignore
              onChange={(newValue: ISelectMultiValue) => {
                const value = newValue.map(item => Number(item.value));
                if (value.length) {
                  if (value.includes(0)) {
                    dispatch(setWorkShiftIds([0, ...workingShiftsTypes.map(item => item.id)]));
                  } else {
                    dispatch(setWorkShiftIds(value));
                  }
                } else {
                  dispatch(setWorkShiftIds([workShiftId]));
                }
              }}
              defaultValue={prepareOptions(preparedWorkShiftIds)}
              disabled={
                isSupervisor &&
                (shift?.type !== EShiftType.ACCEPTED || shift?.status !== EShiftStatus.IN_PROGRESS)
              }
              iconName={ICON_TYPE.WORKING_SHIFTS}
              placeholder={PLACEHOLDER.NONE}
              multi
            />
          )}
          <ComboboxField
            select={{
              addSelectAll: true,
              selectAllText: 'Все цеха',
              emptyBehavior: 'all',
              placeholder: ' Все цеха',
              options: preparedDepartments,
              classNameWrapper: 'layout-screen__select',
              defaultValue: prepareOptions(preparedDepartmentIds),
              multi: true,
              // @ts-ignore
              onChange: (newValue: ISelectMultiValue) => {
                const value = newValue.map(item => Number(item.value));
                dispatch(setDepartmentIds(value));
              },
              disabled:
                userDepartments.length <= 1 ||
                (isSupervisor &&
                  (shift?.type !== EShiftType.ACCEPTED ||
                    shift?.status !== EShiftStatus.IN_PROGRESS)),
            }}
            className={'layout-screen__select'}
          />
          {isSupervisor &&
            (workShiftIds.length !== 1 ||
              !workShiftIds.includes(workShiftId) ||
              departmentIds.length !== 1 ||
              !departmentIds.includes(departmentId)) &&
            !!workShiftId &&
            !!departmentId && (
              <Button
                text="Сбросить"
                icon
                iconType={ICON_TYPE.REVERT}
                type={BUTTON_TYPE.ICON}
                onClick={resetData}
                background={COLORS.TRANSPARENT}
              />
            )}
        </div>
        {isDepartment ||
        isView ||
        (shift?.check_in_time &&
          isSupervisor &&
          moment().format(FORMAT_MOMENT.DASH_YYYYMMDD) <= currentDay &&
          (shift.type !== EShiftType.ACCEPTED || shift?.status !== EShiftStatus.DONE)) ? (
          <>
            <ScreenBlock
              defaultSpoiler={false}
              name="staistics"
              title={titlesMap.statistics}
              showHide
            >
              <TasksStatistic />
            </ScreenBlock>
            <ScreenBlock
              name="work-requests"
              title="Заявки:"
              showHide
              buttons={
                <>
                  <div
                    className={`tabs__tab ${workRequestsTab === 0 ? 'active' : ''}`}
                    onClick={() => handelWorkRequestTabClick(0)}
                  >
                    ТОиР
                  </div>
                  <div
                    className={`tabs__tab ${workRequestsTab === 1 ? 'active' : ''}`}
                    onClick={() => handelWorkRequestTabClick(1)}
                  >
                    ПОС
                  </div>
                  {workRequestsTab === 1 && (
                    <Button
                      type={BUTTON_TYPE.ICON}
                      className={'tabs__tab '}
                      text="Создать заявку"
                      onClick={handleCreateWorkRequestClick}
                      icon
                      iconType={ICON_TYPE.CREATE}
                    />
                  )}
                </>
              }
            >
              <WorkRequests type={workRequestsTab} />
            </ScreenBlock>
            <div className="templates">
              <div className="templates__controls">
                <h1 className="templates__title">Шаблоны</h1>
              </div>
              <Templates />
            </div>
            <div className="layout-screen__controls">
              <div className="tabs">
                <div
                  className={`tabs__tab ${tab === 0 ? 'active' : ''}`}
                  onClick={() => handelClick(0)}
                >
                  Расписание
                </div>
                {companyOptions.use_task_timeless && (
                  <div
                    className={`tabs__tab ${tab === 1 ? 'active' : ''}`}
                    onClick={() => handelClick(1)}
                  >
                    Задачи без времени
                  </div>
                )}
              </div>
              {(dayIsShift && isDepartment) || shift?.status !== EShiftStatus.DONE ? (
                <Button
                  type={BUTTON_TYPE.ICON}
                  text="Создать задачу"
                  onClick={handleCreateClick}
                  icon
                  iconType={ICON_TYPE.CREATE}
                />
              ) : null}
            </div>
          </>
        ) : null}
        <section className="task-carousel">
          {isSupervisor && (!shift?.check_in_time || shift?.status === EShiftStatus.DONE) ? (
            <TaskForm shift={shift} options={companyOptions} isSelectedToday={isSelectedToday} />
          ) : tab === 0 ? (
            <TaskCarousel />
          ) : (
            <TasksNoTime />
          )}
        </section>
      </div>
    </div>
  );
};

export default TasksScreen;
