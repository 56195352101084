import * as React from 'react';
import { useSelector } from 'react-redux';
import { UserSearch } from '~/containers/tasks/popups/add-user/search';
import { UserBadge } from '~/containers/tasks/popups/add-user/user-badge';
import { Alphabet } from '~/containers/tasks/popups/add-user/alphabet';
import { INewUser } from '@smena.wfm/api';
import { selectTodayUsers } from '~/redux/selectors/usersSelectors';
import { BUTTON_TYPE, ICON_TYPE } from '~/helpers/constants';
import Button from '~/components/form/buttons/Button';

interface IAddUserPage {
  onAdd: (id: number) => void;
  onRemove: (id: number) => void;
  users: number[];
  setPage: (value: boolean) => void;
  filters?: {
    department_id?: number;
  };
}

export const AddUserPage = ({
  onAdd,
  onRemove,
  users: selectedUsers,
  setPage,
  filters,
}: IAddUserPage) => {
  const [users, setUsers] = React.useState<INewUser[]>([]);
  const [usersSelected, setUsersSelected] = React.useState<INewUser[]>([]);
  const [usersWithoutSelected, setUsersWithoutSelected] = React.useState<INewUser[]>([]);
  const handleClickBack = React.useCallback(() => {
    if (setPage) {
      setPage(false);
    }
  }, []);

  const usersToday = useSelector(selectTodayUsers).filter(user => {
    if (filters) {
      if (filters.department_id) {
        return user.departments_ids.includes(Number(filters.department_id));
      }
    }
    return user;
  });

  React.useEffect(() => {
    setUsersSelected(usersToday.filter(user => selectedUsers.includes(user.id)));
    setUsersWithoutSelected(usersToday.filter(user => !selectedUsers.includes(user.id)));
  }, [selectedUsers]);

  const handleSearchChange = React.useCallback((values: INewUser[]) => {
    setUsers(values);
  }, []);

  return (
    <>
      <div className="users-add__header">
        <Button
          type={BUTTON_TYPE.ICON}
          text="назад"
          className="back"
          onClick={handleClickBack}
          icon
          iconType={ICON_TYPE.ARROW_PREV}
        />
      </div>
      <div className="users-add__title">Добавить исполнителя</div>
      <div className="users-add__body">
        <UserSearch onSearchChange={handleSearchChange} users={usersWithoutSelected} />
        {!!usersSelected.length && (
          <div className="add-person-popup__added">
            {usersSelected.map(user => (
              <UserBadge key={user.id} onRemove={onRemove} user={user} />
            ))}
          </div>
        )}
        <Alphabet value={users} onUserAdd={onAdd} />
      </div>
    </>
  );
};
