import { Table } from 'antd';
import { ReactComponent as CheckMarkSvg } from '~/assets/check-mark.svg';
import { ReactComponent as RedCrossSvg } from '~/assets/red-cross.svg';
import { HTMLAttributes, memo, ReactNode, SyntheticEvent, useMemo, useState } from 'react';
import type { ColumnsType, ColumnType } from 'antd/es/table';
import type { ResizeCallbackData } from 'react-resizable';
import { Resizable } from 'react-resizable';
import { ICustomFieldTableColumn, ICustomFieldTableData } from '@smena.wfm/api';

interface IWorkRequestTable {
  dataSource: ICustomFieldTableData[];
  columns: ICustomFieldTableColumn[];
  showHeader?: boolean;
}

const setColumnAlign = (type: string) => {
  switch (type) {
    case 'string':
      return 'left';
    case 'number':
      return 'right';
    case 'boolean':
      return 'center';
    default:
      return 'left';
  }
};

const ResizableTitle = (
  props: HTMLAttributes<any> & {
    onResize: (e: SyntheticEvent, data: ResizeCallbackData) => void;
    width: number;
  },
) => {
  const { onResize, width, ...restProps } = props;
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle work-request-popup__table-draggableBorder"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

const WorkRequestTable = (props: IWorkRequestTable) => {
  const { dataSource, columns, showHeader } = props;
  const initialColumns = useMemo(
    () =>
      columns.map(column => {
        const data: ColumnType<ICustomFieldTableData> = {
          key: column.key,
          width: 100,
          title: column.label,
          dataIndex: column.key,
          align: setColumnAlign(column.type),
          render: (text: string) => {
            let content: ReactNode = text;
            if (column.type === 'boolean') {
              content = Boolean(text) ? <CheckMarkSvg /> : <RedCrossSvg />;
            }
            return content;
          },
        };
        return data;
      }),
    [columns],
  );
  const [tableColumns, setColumns] = useState<ColumnType<ICustomFieldTableData>[]>(initialColumns);

  const handleResize: Function = (index: number) => (
    _: SyntheticEvent,
    { size }: ResizeCallbackData,
  ) => {
    const newColumns = [...tableColumns];
    newColumns[index] = {
      ...newColumns[index],
      width: size.width,
    };
    setColumns(newColumns);
  };

  const mergeColumns: ColumnsType<ICustomFieldTableData> = tableColumns.map(
    (col, index) =>
      ({
        ...col,
        onHeaderCell: column => ({
          width: (column as ColumnType<ICustomFieldTableData>).width,
          onResize: handleResize(index),
        }),
      } as ColumnType<ICustomFieldTableData>),
  );

  return (
    <Table
      className="work-request-popup__table"
      dataSource={dataSource}
      columns={mergeColumns}
      showHeader={showHeader ?? true}
      components={{
        header: {
          cell: ResizableTitle,
        },
      }}
      scroll={{ x: 600, y: 272 }}
      pagination={false}
      bordered
      tableLayout="auto"
    />
  );
};

export default memo(WorkRequestTable);
