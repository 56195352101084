import { IGenericDirectory } from '@smena.wfm/api';
import { createEntityAdapter, createSlice, Dictionary, EntityId } from '@reduxjs/toolkit';

export const tasksStatusesAdapter = createEntityAdapter<IGenericDirectory>({
  selectId: tasksStatuses => tasksStatuses.id,
});

export interface ITasksStatusesState {
  ids: EntityId[];
  entities: Dictionary<IGenericDirectory>;
}

const tasksStatusesSlice = createSlice({
  name: 'taskStatuses',
  initialState: tasksStatusesAdapter.getInitialState(),
  reducers: {
    create: tasksStatusesAdapter.setMany,
    update: tasksStatusesAdapter.upsertMany,
    remove: tasksStatusesAdapter.removeMany,
    clear: tasksStatusesAdapter.removeAll,
  },
});

export default tasksStatusesSlice.reducer;

export const { create, update, remove, clear } = tasksStatusesSlice.actions;
