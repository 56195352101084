import * as React from 'react';
import Popup from '~/components/popup/Popup';
import AsidePopupBody from '~/components/popup/AsidePopupBody';
import { useSelector } from 'react-redux';
import { TaskStatus } from '~/components/task-status';
import { GeoStatus } from '~/components/geo-status';
import { Title } from '~/containers/tasks/popups/task-popup/title';
import { TYPE_ROLE, FORMAT_MOMENT } from '~/helpers/constants';
import { TaskList } from '~/containers/tasks/popups/task-popup/task-list';
import { FileInput } from '~/components';
import moment from 'moment';
import { ETaskStatus, INewTaskLog, INewGeoItem, INewTask } from '@smena.wfm/api';
import TaskAssessment from '~/containers/tasks/popups/task-popup/task-assessment';
import { TaskStart } from '~/containers/tasks/popups/task-popup/task-start';
import { selectUserRole, selectCurrentUserId } from '~/redux/selectors/authSelectors';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import { selectZones } from '~/redux/selectors/zonesSelectors';
import { selectTasksTypes } from '~/redux/selectors/tasksTypesSelectors';
import { selectTasksGeoTypes } from '~/redux/selectors/tasksGeoTypesSelectors';
import * as R from 'ramda';
import { TaskHistory } from '~/containers/tasks/popups/task-popup/history';
import { selectShifts } from '~/redux/selectors/shiftsSelectors';
import { getLocalTimeFormat } from '~/helpers/convertToUnix';
import AsidePopupHeader from '~/components/popup/AsidePopupHeader';
import convertTimeHM from '~/helpers/convertTimeHM';
import UsersField from '~/components/form/users/Users';
import CheckboxesField from '~/components/form/checkbox/Checkbox';
import Priority from '~/components/priority/Priority';

interface IViewTaskPopup {
  task: any;
}

const ViewTaskPopup = ({ task }: IViewTaskPopup) => {
  const userRole = useSelector(selectUserRole);
  const userId = useSelector(selectCurrentUserId);
  const companyOptions = useSelector(selectCompanyOptions);
  const zones = useSelector(selectZones);
  const shift = useSelector(selectShifts).find(shift => shift.id === task?.shift_id);
  const taskGeoTypes = useSelector(selectTasksGeoTypes).find(t => t.id === task?.geo_type);
  const taskType = useSelector(selectTasksTypes).find(t => t.id === task?.type_id);

  const sortByDate = R.sortWith([R.ascend(R.prop('date'))]);
  const diff = moment(task?.end_time).diff(moment(task?.begin_time));

  const userTask = task?.tasks?.map((item: INewTask) => item?.user_id);

  const isNotTimeless = task?.start_date && task?.end_date;

  return (
    <Popup aside className="viewTaskPopup">
      <>
        <AsidePopupHeader />
        <AsidePopupBody>
          <div>
            <div className="viewTaskPopup__block">
              <TaskStatus status={task?.status} isExpired={task.is_expired} />
              <GeoStatus status={task?.status} geo_status={task?.geo_status} />
              <div className="task-title">
                <Title onSelectItem={() => {}} disabled value={task?.title} />
                {(task?.status === ETaskStatus.E_DEFAULT ||
                  task?.status === ETaskStatus.E_IN_PROGRESS) &&
                  task?.user_id === userId &&
                  [TYPE_ROLE.SUPERVISOR].includes(userRole) && <TaskList id={task?.id} />}
              </div>
            </div>
            <div className="viewTaskPopup__block">
              <div className="viewTaskPopup__blockTitle">Приоритет</div>
              <div className="viewTaskPopup__blockText">
                <Priority id={task.priority} />
              </div>
            </div>
            <div className="viewTaskPopup__block">
              <div className="viewTaskPopup__times">
                <div className="viewTaskPopup__item">
                  <p className="viewTaskPopup__itemTitle">Регламентное время</p>
                  <p className="viewTaskPopup__itemText">
                    {task?.duration >= 0
                      ? convertTimeHM(task?.duration, false).text
                      : convertTimeHM(diff, true).text}
                  </p>
                </div>
                <div className="viewTaskPopup__item">
                  <p className="viewTaskPopup__itemTitle">
                    {isNotTimeless ? 'Период выполнения' : 'Время выполнения'}
                  </p>
                  <p className="viewTaskPopup__itemText">
                    {isNotTimeless ? (
                      <>
                        {getLocalTimeFormat(
                          task?.start_date,
                          companyOptions?.time_zone,
                          FORMAT_MOMENT.DDMMYYYY,
                        )}
                        {' – '}
                        {getLocalTimeFormat(
                          task?.end_date,
                          companyOptions?.time_zone,
                          FORMAT_MOMENT.DDMMYYYY,
                        )}
                      </>
                    ) : (
                      <>
                        {moment
                          .utc(task?.begin_time)
                          .utcOffset(companyOptions.time_zone)
                          .format(FORMAT_MOMENT.HHMM)}
                        {' – '}
                        {moment
                          .utc(task?.end_time)
                          .utcOffset(companyOptions.time_zone)
                          .format(FORMAT_MOMENT.HHMM)}
                      </>
                    )}
                  </p>
                </div>
              </div>
              {Boolean(task?.unfixed) && (
                <CheckboxesField
                  label="Позволять начинать вне расписания"
                  className="taskPopup__timesCheckbox"
                  defaultValue={Boolean(task.unfixed)}
                  disabled
                />
              )}
            </div>
            <div className="viewTaskPopup__block">
              <div className="viewTaskPopup__description">
                <p className="viewTaskPopup__descriptionTitle">Описание</p>
                <p className="viewTaskPopup__descriptionText">{task?.description}</p>
              </div>
              <FileInput className="viewTaskPopup__files" value={task?.images || []} readonly />
            </div>
            <div className="viewTaskPopup__block">
              <UsersField
                onGoToSearch={() => null}
                value={
                  task?.tasks ? userTask : shift?.user_id ? [shift.user_id] : [task?.user_id] ?? []
                }
                readonly
              />
            </div>
            {taskType?.name && (
              <div className="viewTaskPopup__block">
                <div className="viewTaskPopup__blockTitle">Тип задачи</div>
                <div className="viewTaskPopup__blockText">{taskType.name}</div>
              </div>
            )}
            {Boolean(task?.geo_type) && !!taskGeoTypes?.name && (
              <div className="viewTaskPopup__block">
                <div className="viewTaskPopup__blockTitle">Гео</div>
                <div className="viewTaskPopup__blockText">{taskGeoTypes.name}</div>
              </div>
            )}
            {task?.geo?.length === 1 && (
              <div className="viewTaskPopup__block">
                <div className="viewTaskPopup__blockTitle">Зона</div>
                <div className="viewTaskPopup__blockText">
                  {zones.find(z => z.id === task?.geo[0]?.id)?.name}
                </div>
              </div>
            )}
            {task?.geo && task?.geo?.length > 1 && (
              <div className="viewTaskPopup__block">
                <div className="route-zones route-zones_edit">
                  <h3 className="time-inputs__title">Зоны</h3>
                  <div className="route-zones__route">
                    {task?.geo.map((geo: INewGeoItem) => (
                      <p
                        className={`route-zones__zone ${
                          zones.filter(zone => Number(zone?.id) === geo.id)[0]?.name ??
                          'route-zones__zone--delete'
                        }`}
                      >
                        {geo?.status ? (
                          <i className="route-zones__mark approved" />
                        ) : geo?.checkpoint ? (
                          <i className="route-zones__mark checkpoint" />
                        ) : (
                          ''
                        )}
                        {zones.filter(zone => Number(zone?.id) === geo.id)[0]?.name ??
                          'Зона удалена'}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            )}
            <div className="viewTaskPopup__block">
              {Boolean(task.need_supervisor_test) && (
                <CheckboxesField
                  label="Требует проверки начальника смены"
                  defaultValue={Boolean(task.need_supervisor_test)}
                  disabled
                />
              )}
              {Boolean(task.need_photo_report) && (
                <CheckboxesField
                  label="Требует фотоотчет перед началом"
                  defaultValue={Boolean(task.need_photo_report)}
                  disabled
                />
              )}
              {Boolean(task.need_department_test) && (
                <CheckboxesField
                  label="Требует проверки начальника участка"
                  defaultValue={Boolean(task.need_department_test)}
                  disabled
                />
              )}
              {Boolean(task.need_path_photo_report) && (
                <CheckboxesField
                  label="Требует фотоотчет по маршруту"
                  defaultValue={Boolean(task.need_path_photo_report)}
                  disabled
                />
              )}
              {Boolean(task.is_comment) && (
                <CheckboxesField
                  label="Требует комментария"
                  defaultValue={Boolean(task.is_comment)}
                  disabled
                />
              )}
            </div>
            <TaskHistory
              data={(((sortByDate(task?.logs) as unknown) as INewTaskLog[]) || []).slice(0, 3)}
              inside
              task={task}
            />
            {(Boolean(task?.need_supervisor_test) || Boolean(task?.need_department_test)) &&
              [
                ETaskStatus.E_VALIDATION_SV,
                ETaskStatus.E_VALIDATION,
                ETaskStatus.E_REJECTED_SV,
                ETaskStatus.E_REJECTED_D,
                ETaskStatus.E_COMPLETE,
              ].includes(task?.status) && <TaskAssessment task={task} />}
          </div>
        </AsidePopupBody>
        {[
          ETaskStatus.E_DEFAULT,
          ETaskStatus.E_IN_PROGRESS,
          ETaskStatus.E_EXPIRED,
          ETaskStatus.E_PAUSE,
        ].includes(task?.status) &&
          task?.user_id === userId &&
          [TYPE_ROLE.SUPERVISOR].includes(userRole) && (
            <TaskStart
              id={task?.id}
              status={task?.status}
              need_photo_report_before_start={task?.need_photo_report_before_start}
              need_photo_report_after_complete={task?.need_photo_report_after_complete}
              geo_type={task?.geo_type}
            />
          )}
      </>
    </Popup>
  );
};

export default ViewTaskPopup;
