import { useSelector } from 'react-redux';
import * as React from 'react';
import Api, { ETaskCheckStatus, ETaskStatus, INewTask, ITaskCheck } from '@smena.wfm/api';
import { toast } from 'react-toastify';
import { selectProfile, selectUsers } from '~/redux/selectors/usersSelectors';
import { TaskAssessmentSupervisor } from './task-assessment-supervisor';
import { TaskAssessmentDepartment } from './task-assessment-department';
import { TYPE_ROLE, COLORS } from '~/helpers/constants';
import { selectUser } from '~/redux/selectors/usersSelectors';
import { useDispatch } from 'react-redux';
import { closePopup } from '~/redux/modules/popupModule';
import Button from '~/components/form/buttons/Button';
import errorHandler from '~/utils/errorHandler';

type TaskAssessmentProps = {
  task: INewTask;
};

const TaskAssessment = ({ task }: TaskAssessmentProps) => {
  const { status } = task;
  const dispatch = useDispatch();

  const profile = useSelector(selectProfile);
  const [resetEditDepartment, setResetEditDepartment] = React.useState(false);
  const [resetEditSupervisor, setResetEditSupervisor] = React.useState(false);
  const [buttonStatusDepartment, setButtonStatusDepartment] = React.useState<number | null>(null);
  const [buttonStatusSupervisor, setButtonStatusSupervisor] = React.useState<number | null>(null);

  const users = useSelector(selectUsers);
  const userAuthor = useSelector(selectUser(task.author_id));
  const userTask = useSelector(selectUser(task.user_id));

  const isSupervisor = profile?.role_id === TYPE_ROLE.SUPERVISOR;
  const isDepartment = profile?.role_id === TYPE_ROLE.DEPARTMENT;

  const checks = task?.checks || [];

  const supervisorLog = checks.find((entry: ITaskCheck) => {
    const taskCheckUser = users.find(user => user.id === entry.user_id);
    return taskCheckUser?.role_id === TYPE_ROLE.SUPERVISOR;
  });

  const departmentLog = checks.find((entry: ITaskCheck) => {
    const taskCheckUser = users.find(user => user.id === entry.user_id);
    return taskCheckUser?.role_id === TYPE_ROLE.DEPARTMENT;
  });

  const isSupervisorEdit = status === ETaskStatus.E_VALIDATION_SV && isSupervisor;

  const isDepartmentEdit = status === ETaskStatus.E_VALIDATION && isDepartment;

  const [commentValue, setCommentValue] = React.useState(
    (departmentLog && isDepartment && departmentLog.comment) ||
      (supervisorLog && isSupervisor && supervisorLog.comment) ||
      '',
  );

  const onChangeComment = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCommentValue(event.target.value);
  };

  const statusSupervisor =
    buttonStatusSupervisor === ETaskCheckStatus.COMPLETE
      ? ETaskStatus.E_COMPLETE
      : ETaskStatus.E_REJECTED;

  const statusDepartment =
    buttonStatusDepartment === ETaskCheckStatus.COMPLETE
      ? ETaskStatus.E_COMPLETE
      : ETaskStatus.E_REJECTED;

  const updateTaskStatus = async () => {
    const result = await Api.checkResult({
      id: task.id,
      status: isDepartment ? statusDepartment : statusSupervisor,
      comment: commentValue,
    });
    if (result.kind === 'ok') {
      toast.success('Оценка обновлена');
      setResetEditDepartment(false);
      setResetEditSupervisor(false);
      dispatch(closePopup());
    } else errorHandler(result);
  };

  const handleResetEditDepartment = () => {
    setCommentValue((departmentLog && isDepartment && departmentLog.comment) || '');
    setResetEditDepartment(true);
    setButtonStatusDepartment(null);
  };

  const handleResetEditSupervisor = () => {
    setCommentValue((supervisorLog && isSupervisor && supervisorLog.comment) || '');
    setResetEditSupervisor(true);
    setButtonStatusSupervisor(null);
  };

  return (
    <div className="task-assessment">
      <div className="task-assessment__heading">Оценка задачи</div>

      {(supervisorLog || isSupervisorEdit) && userTask?.role_id !== TYPE_ROLE.SUPERVISOR ? (
        <TaskAssessmentSupervisor
          buttonStatus={buttonStatusSupervisor}
          setStatus={setButtonStatusSupervisor}
          task={task}
          resetEdit={resetEditSupervisor}
          log={supervisorLog}
        />
      ) : null}

      {(departmentLog || isDepartmentEdit) && (
        <TaskAssessmentDepartment
          buttonStatus={buttonStatusDepartment}
          setStatus={setButtonStatusDepartment}
          task={task}
          resetEdit={resetEditDepartment}
          log={departmentLog}
        />
      )}

      {(isDepartmentEdit || isSupervisorEdit || resetEditDepartment || resetEditSupervisor) && (
        <div className="task-assessment__comment">
          <textarea
            className="textarea textarea_gray"
            value={commentValue}
            placeholder="Здесь можно написать комментарий"
            onChange={onChangeComment}
          />
        </div>
      )}
      {isDepartment && departmentLog && (
        <div className="task-assessment__reset" onClick={handleResetEditDepartment}>
          сбросить оценку и комментарий
        </div>
      )}

      {isSupervisor &&
        supervisorLog &&
        !departmentLog &&
        userAuthor?.role_id !== TYPE_ROLE.DEPARTMENT &&
        userTask?.role_id !== TYPE_ROLE.SUPERVISOR && (
          <div className="task-assessment__reset" onClick={handleResetEditSupervisor}>
            сбросить оценку и комментарий
          </div>
        )}

      {(isDepartmentEdit || isSupervisorEdit || resetEditDepartment || resetEditSupervisor) &&
        (buttonStatusSupervisor === null || buttonStatusDepartment === null) && (
          <Button text="Сохранить" onClick={updateTaskStatus} background={COLORS.BLUE} />
        )}
    </div>
  );
};

export default TaskAssessment;
