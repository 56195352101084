import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api, {
  ShiftsStatistic,
  IShiftsStatisticForm,
  IShiftsStatistic,
  IShiftsStatisticItem,
  IShiftsStatisticPeriod,
  IShiftsStatisticSum,
} from '@smena.wfm/api';
import errorHandler from '~/utils/errorHandler';
import fileDownload from 'js-file-download';

const iconv = require('iconv-lite');

export const fetchStatistics = createAsyncThunk(
  'statistics/fetchAll',
  async (input: IShiftsStatisticForm) => {
    const response: ShiftsStatistic = await Api.getShiftsStatistic(input);
    if (response.kind === 'ok') {
      return response.data;
    }
    errorHandler(response);
  },
);

export const exportStatistics = createAsyncThunk(
  'statistics/export',
  async (input: IShiftsStatisticForm) => {
    Api.getShiftsStatisticExport(input).then((response: any) => {
      if (response.kind === 'ok') {
        fileDownload(
          response.data,
          //TODO : type file FIX
          iconv.decode(`${response.data.filename}`, 'utf-8'),
        );
      }
      errorHandler(response);
    });
  },
);

interface IStatistics {
  period: IShiftsStatisticPeriod[];
  items: IShiftsStatisticItem[];
  total: IShiftsStatisticSum;
  loading: boolean;
}

const initialState: IStatistics = {
  period: [],
  items: [],
  total: {
    shift_duration: 0,
    planned: 0,
    not_planned: 0,
    worked: 0,
    not_done: 0,
    overworked: 0,
    underworked: 0,
    worked_timeless: 0,
    not_done_timeless: 0,
    overworked_timeless: 0,
    underworked_timeless: 0,
    expired: 0,
    missed_geo: 0,
    free_time: 0,
    worked_total: 0,
    overworked_total: 0,
    underworked_total: 0,
    not_started_total: 0,
    free_time_planned: 0,
  },
  loading: true,
};

export const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchStatistics.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchStatistics.fulfilled, (state, action) => {
      const payload = action.payload as IShiftsStatistic;
      if (!payload) return;
      state.period = payload.periods;
      state.items = payload.items;
      state.total = payload.total;
      state.loading = false;
    });
    builder.addCase(exportStatistics.pending, state => {
      state.loading = true;
    });
    builder.addCase(exportStatistics.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(exportStatistics.rejected, state => {
      state.loading = false;
    });
  },
});

export default statisticsSlice.reducer;
