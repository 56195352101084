import * as React from 'react';
import { useAppSelector } from '~/hooks/redux';
import { selectUserRoleCanEdit, selectCurrentUserId } from '~/redux/selectors/authSelectors';
import { INewShift } from '@smena.wfm/api';

interface ICarouselRow {
  time: string;
  users: INewShift[];
}

export const CarouselRow = ({ time, users }: ICarouselRow) => {
  const userRoleCanEdit = useAppSelector(selectUserRoleCanEdit);
  const userId = useAppSelector(selectCurrentUserId);
  const usersArr = React.useMemo(() => users.map(item => item.user_id), [users]);

  if (userRoleCanEdit) {
    usersArr.push(0);
  }

  return (
    <div className="task-carousel__row">
      <div className="task-carousel__col task-carousel__col_time">{time}</div>
      {usersArr.map((user, idx) => (
        <div
          key={idx}
          className={`task-carousel__col task-carousel__col_person task-carousel__col_task ${
            userId === user ? 'task-carousel__col_person-first' : ''
          }`}
        />
      ))}
    </div>
  );
};
