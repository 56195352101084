import * as React from 'react';
import { ETaskStatus, INewTask, ITaskCheck, ETaskCheckStatus } from '@smena.wfm/api';
import { useAppSelector } from '~/hooks/redux';
import { selectProfile, selectUsers } from '~/redux/selectors/usersSelectors';
import { TYPE_ROLE } from '~/helpers/constants';

interface ITaskAssessmentSupervisor {
  task: INewTask;
  resetEdit: boolean;
  buttonStatus: number | null;
  setStatus: (status: number) => void;
  log?: ITaskCheck;
}
export const TaskAssessmentSupervisor = ({
  task,
  resetEdit,
  buttonStatus,
  setStatus,
  log,
}: ITaskAssessmentSupervisor) => {
  const { status, need_supervisor_test } = task;
  const profile = useAppSelector(selectProfile);
  const users = useAppSelector(selectUsers);

  const isSupervisor = profile?.role_id === TYPE_ROLE.SUPERVISOR;

  let supervisorProfileUserName = '';

  if (log) {
    const supervisorProfile = users.find(user => user.id === log.user_id);
    supervisorProfileUserName =
      supervisorProfile?.lastname +
      ' ' +
      supervisorProfile?.firstname +
      ' ' +
      supervisorProfile?.middlename;
  }

  const handleButtonAccept = React.useCallback(() => {
    if (!need_supervisor_test) return;
    setStatus(ETaskCheckStatus.COMPLETE);
  }, [need_supervisor_test]);

  const handleButtonReject = React.useCallback(() => {
    setStatus(ETaskCheckStatus.REJECTED);
  }, []);

  return (
    <div className="task-assessment__user">
      <div className="user-assessment">
        <div className="user-info">
          <div className="user-position">Начальник смены</div>
          <div className="user-name">
            {isSupervisor
              ? profile?.lastname + ' ' + profile?.firstname + ' ' + profile?.middlename
              : log && supervisorProfileUserName}
          </div>
        </div>
        <div className="user-buttons">
          {(status === ETaskStatus.E_VALIDATION_SV || resetEdit) && (
            <>
              <div
                className={`button_accept ${
                  buttonStatus === ETaskCheckStatus.COMPLETE && 'button_accept-active'
                }`}
                onClick={handleButtonAccept}
              >
                принять
              </div>
              <div
                className={`button_reject ${
                  buttonStatus === ETaskCheckStatus.REJECTED && 'button_reject-active'
                }`}
                onClick={handleButtonReject}
              >
                отклонить
              </div>
            </>
          )}
          {log?.status === ETaskCheckStatus.REJECTED && !resetEdit && (
            <div className="button_rejected">отклонено</div>
          )}
          {log?.status === ETaskCheckStatus.COMPLETE && !resetEdit && (
            <div className="button_accepted">принято</div>
          )}
        </div>
      </div>
      {!resetEdit && <div className="user-comment">{log && log.comment}</div>}
    </div>
  );
};
