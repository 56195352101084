import { IZone } from '@smena.wfm/api';
import { createEntityAdapter, createSlice, Dictionary, EntityId } from '@reduxjs/toolkit';

export const zonesAdapter = createEntityAdapter<IZone>({
  selectId: zone => zone.id,
});

export interface IZonesState {
  ids: EntityId[];
  entities: Dictionary<IZone>;
}

const zonesSlice = createSlice({
  name: 'zones',
  initialState: zonesAdapter.getInitialState(),
  reducers: {
    create: zonesAdapter.setMany,
    update: zonesAdapter.upsertMany,
    remove: zonesAdapter.removeMany,
    clear: zonesAdapter.removeAll,
  },
});

export default zonesSlice.reducer;

export const { create, update, remove, clear } = zonesSlice.actions;
