import * as React from 'react';
import { INewUser } from '@smena.wfm/api';
import { ReactComponent as CircleCloseSvg } from '~/assets/circle-close.svg';

interface IUserBadge {
  onRemove: (id: number, name?: string) => void;
  user: INewUser;
}

export const UserBadge = ({ onRemove, user }: IUserBadge) => {
  const text = React.useMemo(() => {
    return (
      user.lastname.firstToUpper() +
      ' ' +
      user.firstname.charAt(0).toUpperCase() +
      '.' +
      (user.middlename ? user.middlename.charAt(0).toUpperCase() : '')
    );
  }, [user]);

  const handleClickRemove = React.useCallback(() => {
    onRemove(user.id, `${user?.lastname} ${user?.firstname}`);
  }, [user.id, onRemove]);

  return (
    <div className="add-person-popup__added-person">
      <span className="add-person-popup__added-person-name">{text}</span>
      <button onClick={handleClickRemove} className="add-person-popup__added-person-delete">
        <CircleCloseSvg />
      </button>
    </div>
  );
};
