import * as React from 'react';
import Api, { EShiftStatus, EShiftType, IDomainOptions, INewShift } from '@smena.wfm/api';
import moment from 'moment/moment';
import { useAppSelector } from '~/hooks/redux';
import { selectWorkingShift } from '~/redux/selectors/workingShiftsSelectors';
import { selectDay } from '~/redux/selectors/stateSelectors';
import { getLocalMomentTime } from '~/helpers/convertToUnix';
import { FORMAT_MOMENT, COLORS } from '~/helpers/constants';
import Button from '~/components/form/buttons/Button';
import convertTimeHM from '~/helpers/convertTimeHM';

interface ITaskForm {
  shift?: INewShift;
  options: IDomainOptions;
  isSelectedToday: boolean;
}

export const TaskForm = ({ shift, options, isSelectedToday }: ITaskForm) => {
  const currentDay = useAppSelector(selectDay);
  const workShift = useAppSelector(selectWorkingShift(shift?.working_shift_id));

  const [timeStart, setTimeStart] = React.useState<string | undefined>('');
  const [timeEnd, setTimeEnd] = React.useState<string | undefined>('');
  const [timeAfter, setTimeAfter] = React.useState<string | undefined>('');
  const [dateShift, setDateShift] = React.useState<boolean>();
  const actualMoment = getLocalMomentTime(shift?.start_time, options.time_zone);
  const actualTimeRight = convertTimeHM(moment(actualMoment).diff(moment()), true).text;
  const actualTimeLeft = convertTimeHM(moment(moment()).diff(actualMoment), true).text;
  const shiftDate = getLocalMomentTime(shift?.start_time, options.time_zone).format(
    FORMAT_MOMENT.DASH_YYYYMMDD,
  );

  React.useEffect(() => {
    setTimeStart(
      shift?.check_in_time
        ? getLocalMomentTime(shift?.check_in_time, options.time_zone).format(FORMAT_MOMENT.HHMM)
        : getLocalMomentTime(shift?.start_time, options.time_zone).format(FORMAT_MOMENT.HHMM),
    );
    setTimeEnd(
      shift?.check_out_time
        ? getLocalMomentTime(shift?.check_out_time, options.time_zone).format(FORMAT_MOMENT.HHMM)
        : getLocalMomentTime(shift?.end_time, options.time_zone).format(FORMAT_MOMENT.HHMM),
    );

    setTimeAfter(
      actualMoment.isBefore(moment(), 'minute')
        ? `Вы опаздываете на ${actualTimeLeft}.`
        : `До начала ${actualTimeRight}.`,
    );
  }, [shift, workShift, options]);

  React.useEffect(() => {
    setDateShift(shiftDate === currentDay);
  }, [shiftDate, currentDay]);

  const handleCheckin = React.useCallback(async () => {
    if (shift?.id)
      await Api.checkIn({
        shiftId: shift?.id,
        location: { lat: 0, lng: 0 },
      });
  }, [shift]);

  const handleAccept = React.useCallback(async () => {
    if (shift?.id) {
      await Api.acceptAdditionalShift(shift?.id);
    }
  }, [shift]);

  const handleCancel = React.useCallback(async () => {
    if (shift?.id) {
      await Api.cancelAdditionalShift(shift?.id);
    }
  }, [shift]);

  return (
    <div className="block-form">
      {shift && shift.type === 1 ? (
        <div className="block-form__block">
          <div className="block-form__title">Вы не на смене</div>
          {currentDay > shiftDate && dateShift && (
            <div className="block-form__desc">Вам назначена смена на этот день</div>
          )}
          {dateShift && isSelectedToday && (
            <>
              <div className="block-form__date">
                {timeStart} – {timeEnd}
                {' · '}
                {timeAfter}
              </div>
              <div className="block-form__button-inner">
                <Button text="отклонить" onClick={handleCancel} background={COLORS.PINK} border />
                <Button text="принять" onClick={handleAccept} background={COLORS.BLUE} />
              </div>
            </>
          )}
        </div>
      ) : !shift ||
        shift.type === EShiftType.CANCELED ||
        shift.status === EShiftStatus.DONE ||
        !isSelectedToday ? (
        <div className="block-form__block">
          {moment().format(FORMAT_MOMENT.DASH_YYYYMMDD) <= currentDay ? (
            <div className="block-form__title">Вы не на смене</div>
          ) : (
            <div className="block-form__title">У вас не было смены</div>
          )}
        </div>
      ) : (
        <div className="block-form__block">
          <div className="block-form__title">Вы не на смене</div>
          {dateShift && isSelectedToday && (
            <>
              <div className="block-form__desc">
                Чтобы увидеть список задач — необходимо начать смену
              </div>
              <div className="block-form__date">
                {timeStart} – {timeEnd}
                {' · '}
                {timeAfter}
              </div>
              <div className="block-form__button-inner">
                <Button text="начать смену" onClick={handleCheckin} background={COLORS.BLUE} />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};
