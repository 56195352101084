import * as React from 'react';
import moment from 'moment';
import cx from 'classnames';
import { ETaskStatus, IGenericDirectory, INewGeoItem, INewTask } from '@smena.wfm/api';
import { ReactComponent as RemoveTaskSvg } from '~/assets/basket.svg';
import { ReactComponent as PencilTaskSvg } from '~/assets/pencil.svg';
import { ReactComponent as CloseSvg } from '~/assets/task-close.svg';
import { ReactComponent as ClockSvg } from '~/assets/clock.svg';
import { useOnClickOutside } from '~/hooks';
import { TaskStatus } from '~/components/task-status';
import { GeoStatus } from '~/components/geo-status';
import { selectUserRole, selectCurrentUserId } from '~/redux/selectors/authSelectors';
import { selectTasksTypes } from '~/redux/selectors/tasksTypesSelectors';
import { selectTasksGeoTypes } from '~/redux/selectors/tasksGeoTypesSelectors';
import convertToLocal from '~/helpers/convertToLocal';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import { useAppDispatch, useAppSelector } from '~/hooks/redux';
import { TYPE_ROLE, FORMAT_MOMENT, COLORS, ALERT_TYPE, ALERT } from '~/helpers/constants';
import { openPopup } from '~/redux/modules/popupModule';
import { POPUPS_NAME } from '~/components/PopupManager';
import { getLocalTimeFormat } from '~/helpers/convertToUnix';
import Button from '~/components/form/buttons/Button';
import CheckboxesField from '~/components/form/checkbox/Checkbox';
import { showAlert } from '~/redux/modules/stateModule';
import classNames from 'classnames';

export interface ITaskInfo {
  task?: INewTask & { start_date?: string; end_date?: string; duration?: number };
  read_only: boolean | undefined;
  status: ETaskStatus;
  title: string;
  type_id?: number;
  geo_type?: number;
  geo?: INewGeoItem[];
  geo_status?: number;
  description: string;
  start: string | null;
  end: string | null;
  need_supervisor_test?: number;
  need_department_test?: number;
  need_photo_report_before_start?: boolean;
  need_photo_report_after_complete?: boolean;
  is_comment?: number;
  show: boolean;
  id: number;
  user_id: number;
  onClose: () => void;
  images?: Array<string>;
  is_everyday?: boolean;
  boolPosLeft?: boolean;
  priority: number;
  need_path_photo_report?: number;
  unfixed?: boolean;
}

export const TaskInfo: React.FC<ITaskInfo> = ({
  id,
  start,
  end,
  title,
  type_id,
  geo_type,
  geo_status,
  need_path_photo_report,
  need_supervisor_test,
  need_department_test,
  need_photo_report_before_start,
  need_photo_report_after_complete,
  is_comment,
  status,
  user_id,
  onClose,
  show,
  boolPosLeft,
  read_only,
  task,
  unfixed,
}) => {
  const dispatch = useAppDispatch();
  const ref = React.useRef<HTMLElement>(null);
  const companyOptions = useAppSelector(selectCompanyOptions);
  const userRole = useAppSelector(selectUserRole);
  const tasksType = useAppSelector(selectTasksTypes);
  const tasksGeoTypes = useAppSelector(selectTasksGeoTypes);
  const userId = useAppSelector(selectCurrentUserId);

  const timeStr = React.useMemo(
    () =>
      `${convertToLocal(
        moment(start).format(FORMAT_MOMENT.HHMM),
        false,
        companyOptions.time_zone,
      )} - ${convertToLocal(
        moment(end).format(FORMAT_MOMENT.HHMM),
        false,
        companyOptions.time_zone,
      )}`,
    [start, end],
  );

  const dateStr = React.useMemo(
    () =>
      `${getLocalTimeFormat(
        task?.start_date,
        companyOptions.time_zone,
        FORMAT_MOMENT.DDMMYYYY,
      )} - ${getLocalTimeFormat(task?.end_date, companyOptions.time_zone, FORMAT_MOMENT.DDMMYYYY)}`,
    [start, end],
  );

  const realTimeStr = React.useMemo(() => {
    return `${convertToLocal(
      moment((task?.tracks && task?.tracks[task?.tracks?.length - 1]?.start_time) || start).format(
        FORMAT_MOMENT.HHMM,
      ),
      false,
      companyOptions.time_zone,
    )} - ${convertToLocal(
      moment(
        (task?.tracks && task?.tracks[task?.tracks?.length - 1]?.end_time) ||
          end ||
          moment().subtract('hours', 3).format(FORMAT_MOMENT.YYYYMMDD_HHMMSS),
      ).format(FORMAT_MOMENT.HHMM),
      false,
      companyOptions.time_zone,
    )}`;
  }, [start, end, task?.tracks]);
  const handleClose = React.useCallback(
    (e?: React.MouseEvent) => {
      if (show && onClose) {
        e && e.stopPropagation();
        onClose();
      }
    },
    [onClose, show],
  );

  const handleRemoveTask = React.useCallback(
    (e?: React.MouseEvent) => {
      e && e.stopPropagation();
      const data = {
        title: 'Удалить задачу',
        description: `Вы действительно хотите удалить задачу “${title}” (${moment(start)
          .utc()
          .format(FORMAT_MOMENT.DMMMM)}, ${timeStr})?`,
        name: ALERT.REMOVE_TASK,
        type: ALERT_TYPE.REMOVE,
        data: {
          taskId: id,
        },
      };

      dispatch(showAlert(data));
    },
    [title, id],
  );

  const handleEditTask = (e?: React.MouseEvent) => {
    e && e.stopPropagation();
    handleClose();
    dispatch(
      openPopup({
        name: POPUPS_NAME.EDITE_TASK_POPUP,
        data: {
          task: {
            ...task,
            geo: task?.geo ? task?.geo?.map((geo: { id: any }) => geo?.id ?? geo) : [],
          },
        },
      }),
    );
  };

  const handleTaskClick = React.useCallback(
    (e?: React.MouseEvent) => {
      e && e.stopPropagation();
      handleClose();
      dispatch(openPopup({ name: POPUPS_NAME.VIEW_TASK_POPUP, data: { task: task } }));
    },
    [id, handleClose, task],
  );

  const changeTaskStatus = React.useCallback(() => {
    const status = task?.status === ETaskStatus.E_IN_PROGRESS;

    const data = {
      title: 'Подтвердите действие',
      description: status ? 'Завершить задачу?' : 'Начать задачу?',
      name: ALERT.START_TASK,
      type: ALERT_TYPE.DEFAULT,
      data: {
        taskId: id,
        taskStatus: status ? ETaskStatus.E_COMPLETE : ETaskStatus.E_IN_PROGRESS,
      },
    };

    handleClose();

    dispatch(showAlert(data));
  }, [id, task?.status]);

  useOnClickOutside(ref, handleClose);

  const canShowRemoveTaskButton = (): boolean => {
    const forbiddenToRemove: ETaskStatus[] = [ETaskStatus.E_DEFAULT];

    return forbiddenToRemove.includes(status);
  };

  const isShownRemoveTaskButton: boolean = canShowRemoveTaskButton();

  const canShowEditTaskButton = (): boolean => {
    const forbiddenToRemove: ETaskStatus[] = [ETaskStatus.E_DEFAULT];

    return forbiddenToRemove.includes(status);
  };

  const isShownEditTaskButton: boolean = canShowEditTaskButton();

  const taskType: IGenericDirectory[] = tasksType.filter(type => type.id === type_id);
  const taskGeoType: IGenericDirectory[] = tasksGeoTypes.filter(geo => geo.id === geo_type);

  return (
    <div
      className={cx('task-info', { 'task-info_active': show })}
      ref={ref as React.RefObject<HTMLDivElement>}
      style={
        boolPosLeft
          ? {
              right: 'unset',
              left: '-410px',
            }
          : {}
      }
    >
      {![TYPE_ROLE.VIEW].includes(userRole) && isShownRemoveTaskButton && !read_only && (
        <button className="task-info__remove" onClick={handleRemoveTask}>
          <RemoveTaskSvg />
        </button>
      )}
      {![TYPE_ROLE.VIEW].includes(userRole) && isShownEditTaskButton && !read_only && (
        <button className="task-info__edit" onClick={handleEditTask}>
          <PencilTaskSvg />
        </button>
      )}
      <button className="task-info__close" onClick={handleClose}>
        <CloseSvg />
      </button>
      <TaskStatus status={status} isExpired={task?.is_expired} />
      <GeoStatus status={status} geo_status={geo_status} />
      <h3 className="task-info__title">{title}</h3>
      <div
        className={classNames('task-info__time', {
          'task-info__time-center': !task?.tracks?.length,
        })}
      >
        <ClockSvg />
        <div className="task-info__time-block">
          {task?.tracks?.length ? <span>{realTimeStr} - фактическое время</span> : ''}
          <span>
            {task?.start_date ? `${dateStr} - период выполнения` : `${timeStr}  - плановое время`}
          </span>
        </div>
      </div>
      <div className="task-type">{taskType[0]?.name}</div>
      <div className="task-geo" style={{ marginTop: '10px' }}>
        {taskGeoType[0]?.name}
      </div>
      <p className="task-info__text">{task?.description}</p>
      {Boolean(unfixed) && (
        <CheckboxesField
          label="Позволять начинать вне расписания"
          defaultValue={Boolean(unfixed)}
          disabled
        />
      )}
      {Boolean(need_supervisor_test) && (
        <CheckboxesField
          label="Требует проверки начальника смены"
          defaultValue={Boolean(need_supervisor_test)}
          disabled
        />
      )}
      {Boolean(need_photo_report_before_start) && (
        <CheckboxesField
          label="Требует фотоотчет перед началом"
          defaultValue={Boolean(need_photo_report_before_start)}
          disabled
        />
      )}
      {Boolean(need_photo_report_after_complete) && (
        <CheckboxesField
          label="Требует фотоотчет после завершения"
          defaultValue={Boolean(need_photo_report_after_complete)}
          disabled
        />
      )}
      {Boolean(need_department_test) && (
        <CheckboxesField
          label="Требует проверки начальника участка"
          defaultValue={Boolean(need_department_test)}
          disabled
        />
      )}
      {Boolean(need_path_photo_report) && (
        <CheckboxesField
          label="Требует фотоотчет по маршруту"
          defaultValue={Boolean(need_path_photo_report)}
          disabled
        />
      )}
      {Boolean(is_comment) && (
        <CheckboxesField label="Требует комментария" defaultValue={Boolean(is_comment)} disabled />
      )}
      {user_id === userId &&
      [
        ETaskStatus.E_DEFAULT,
        ETaskStatus.E_IN_PROGRESS,
        ETaskStatus.E_PAUSE,
        ETaskStatus.E_EXPIRED,
      ].includes(status) &&
      !need_photo_report_before_start &&
      !is_comment &&
      !geo_type ? (
        <div className="task-info__buttons">
          <Button
            text="Перейти в задачу"
            onClick={handleTaskClick}
            background={COLORS.BLUE}
            transparent
          />
          <Button
            text={task?.status === ETaskStatus.E_IN_PROGRESS ? 'Завершить задачу' : 'Начать задачу'}
            onClick={changeTaskStatus}
            background={COLORS.BLUE}
          />
        </div>
      ) : (
        <Button
          text="Перейти в задачу"
          onClick={handleTaskClick}
          background={COLORS.BLUE}
          transparent
        />
      )}
      {user_id === userId && (need_photo_report_before_start || geo_type) && (
        <div className="task-info__banner">Задачу требуется выполнять в приложении</div>
      )}
    </div>
  );
};
