import React from 'react';
import { useSelector } from 'react-redux';
import { INewTaskTemplate, ITaskTimelessTemplate } from '@smena.wfm/api';
import { TitleAutocomplete } from './title-autocomplete';
import { selectUserRole } from '~/redux/selectors/authSelectors';
import { TYPE_ROLE } from '~/helpers/constants';

interface ITitle
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  onSelectItem: (item: INewTaskTemplate | ITaskTimelessTemplate) => void;
}

export const Title = ({ onSelectItem, ...rest }: ITitle) => {
  const isReadOnly = useSelector(selectUserRole) === TYPE_ROLE.VIEW;

  return (
    <TitleAutocomplete
      type="text"
      className="aside-popup__title aside-popup__title_input"
      placeholder="Добавьте название"
      readOnly={isReadOnly}
      blurOnSelect
      onClickSelect={onSelectItem}
      {...rest}
    />
  );
};
