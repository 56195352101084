import * as React from 'react';
import { INewUser } from '@smena.wfm/api';
import * as R from 'ramda';
import { UserGroup } from './user-group';

interface IAlphabet {
  value: INewUser[];
  onUserAdd: (id: number) => void;
}

const groupByFirstLetterLastname = R.compose(
  // @ts-ignore
  R.sortWith([R.ascend(R.prop(0))]),
  R.toPairs,
  R.groupBy((user: INewUser) => {
    return user.lastname.charAt(0).toUpperCase();
  }),
);

export const Alphabet = ({ value, onUserAdd }: IAlphabet) => {
  const grouped = React.useMemo(() => {
    // @ts-ignore
    return groupByFirstLetterLastname(value) as [string, INewUser[]][];
  }, [value]);

  return (
    <div className="alphabet">
      {grouped.map(([letter, users]) => (
        <UserGroup key={letter} letter={letter} users={users} onUserAdd={onUserAdd} />
      ))}
      {!grouped.length && <p className="alphabet__none">ничего не найдено</p>}
    </div>
  );
};
