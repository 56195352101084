import * as React from 'react';
import { today } from '~/redux/modules/periodsModule';
import moment from 'moment';
import { StatisticsFilters } from '~/containers/statistics/filter/Filter';
import { useAppSelector } from '~/hooks/redux';
import { useDispatch } from 'react-redux';
import { setReportsFilters } from '~/redux/reducers/ReportsFiltersSlice';
import { setReportsFilterItem } from '~/redux/reducers/ReportsFiltersSlice';
import { selectCurrentUserId } from '~/redux/selectors/authSelectors';
import {
  selectDataDepartments,
  selectDepartmentsByIdsFilter,
} from '~/redux/selectors/departmentsSelectors';
import { selectDataProfessions } from '~/redux/selectors/professionsSelectors';
import { selectDataWorkingShifts } from '~/redux/selectors/workingShiftsSelectors';
import { selectDataUsers, selectUser } from '~/redux/selectors/usersSelectors';
import { getLocalTimeFormat } from '~/helpers/convertToUnix';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import Legends from '~/components/statistic/Legends';
import ChartDoughnut from '~/components/statistic/ChartDoughnut';
import ChartBar from '~/components/statistic/ChartBar';
import { EStatisticStep, IShiftsStatisticForm, IShiftsStatisticItemPeriod } from '@smena.wfm/api';
import { BUTTON_TYPE, COLORS, FORMAT_MOMENT, ICON_TYPE, TYPE_ROLE } from '~/helpers/constants';
import { exportStatistics, fetchStatistics } from '~/redux/modules/statisticsModule';
import convertTimeHM from '~/helpers/convertTimeHM';
import SearchInput from '~/components/SearchInput';
import { titlesMap } from '~/utils/titles';
import getDatesIsSame from '~/helpers/getDatesIsSame';
import Button from '~/components/form/buttons/Button';
import ChartDiagrams from '~/components/statistic/ChartDiagrams';

const StatisticsScreen = () => {
  const momentFormat = 'YYYY/MM/DD';
  const dispatch = useDispatch();
  const [search, setSearch] = React.useState('');
  const [steps, setSteps] = React.useState<string>(EStatisticStep.DAY);
  const [filterDay, setFilterDay] = React.useState(
    today.map((date: number) => moment(moment.unix(date).format(momentFormat))),
  );
  const companyOption = useAppSelector(selectCompanyOptions);
  const timeZone = companyOption.time_zone;
  const dataDepartments = useAppSelector(selectDataDepartments);
  const userId = useAppSelector(selectCurrentUserId);
  const currentUser = useAppSelector(selectUser(userId));
  const dataDepartmentsView = useAppSelector(
    selectDepartmentsByIdsFilter([
      ...(currentUser?.departments_ids ?? []),
      ...(currentUser?.view_departments_ids ?? []),
    ]),
  );
  const dataProfessions = useAppSelector(selectDataProfessions);
  const dataWorkingShifts = useAppSelector(selectDataWorkingShifts);
  const users = useAppSelector(selectDataUsers);

  const { reportsFilters } = useAppSelector(state => state.reportsFilters);
  const { period, items, total, loading } = useAppSelector(state => state.statistics);
  const findCheck = (name: string) => {
    const filter = reportsFilters.find(filter => filter.name === name);
    if (!filter) return [];
    return filter.data.filter(data => data.check).map(data => data.id);
  };

  const onlyChecked = (reportsFilters: any[]) => {
    const activeFilters: any[] = [];
    reportsFilters.forEach(item => {
      item.data.forEach((subItem: { check: any; name: any }) => {
        if (subItem.check) activeFilters.push(item.name + ':' + subItem.name);
      });
    });
    return JSON.stringify(activeFilters);
  };

  const tasksDefaultFilters = React.useMemo(() => {
    let filters = [
      {
        title: 'Цех',
        name: 'department',
        data: dataDepartmentsView,
      },
    ];

    if (companyOption.use_schedule_template) {
      filters.push({
        title: 'Тип смены',
        name: 'shifts',
        data: dataWorkingShifts,
      });
    }

    filters = [
      ...filters,
      {
        title: 'Профессия',
        name: 'profession',
        data: dataProfessions,
      },
      {
        title: 'Исполнитель',
        name: 'users',
        data: users,
      },
    ];
    return filters;
  }, [dataProfessions, dataDepartments, JSON.stringify(dataDepartmentsView), dataWorkingShifts]);

  React.useEffect(() => {
    if (tasksDefaultFilters) {
      dispatch(setReportsFilters(tasksDefaultFilters));
    }
  }, [tasksDefaultFilters]);

  React.useEffect(() => {
    const dates = getDatesIsSame(filterDay[0], filterDay[1], timeZone);
    const input: IShiftsStatisticForm = {
      begin_date: dates.start_date,
      end_date: dates.end_date,
      step: steps as EStatisticStep,
      search: search,
      department_id: findCheck(TYPE_ROLE.DEPARTMENT) as number[],
      working_shift_id: findCheck('shifts') as number[],
      professions_id: findCheck('profession') as number[],
      users_id: findCheck('users') as number[],
    };
    dispatch(fetchStatistics(input));
  }, [search, filterDay, steps, onlyChecked(reportsFilters)]);

  React.useEffect(() => {
    if (findCheck('department').length || findCheck('profession').length) {
      const userFilters = users.filter(item => {
        let checkDep = false;
        findCheck('department').forEach(dep => {
          if (item.department_ids.includes(dep!)) {
            checkDep = true;
          }
        });

        if (checkDep) {
          return item;
        }
      });

      dispatch(
        setReportsFilterItem({
          title: 'Исполнитель',
          name: 'users',
          data: userFilters.filter(item => {
            let checkProf = false;

            if (findCheck('profession').length) {
              findCheck('profession').forEach(prof => {
                if (item.profession_id === prof) {
                  checkProf = true;
                }
              });
            } else {
              checkProf = true;
            }

            if (checkProf) {
              return item;
            }
          }),
        }),
      );
    } else {
      dispatch(
        setReportsFilterItem({
          title: 'Исполнитель',
          name: 'users',
          data: users,
        }),
      );
    }
  }, [
    JSON.stringify(findCheck('department')),
    JSON.stringify(findCheck('profession')),
    dataDepartments,
  ]);

  const saveStat = React.useCallback(() => {
    const dates = getDatesIsSame(filterDay[0], filterDay[1], timeZone);

    const input: IShiftsStatisticForm = {
      begin_date: dates.start_date,
      end_date: dates.end_date,
      step: steps as EStatisticStep,
      search: search?.length ? search : undefined,
      department_id: findCheck(TYPE_ROLE.DEPARTMENT)?.length
        ? findCheck(TYPE_ROLE.DEPARTMENT)
        : undefined,
      working_shift_id: findCheck('shifts')?.length ? findCheck('shifts') : undefined,
      professions_id: findCheck('profession')?.length ? findCheck('profession') : undefined,
      users_id: findCheck('users')?.length ? findCheck('users') : undefined,
    };

    dispatch(exportStatistics(input));
  }, [filterDay[0], filterDay[1], steps, search, findCheck]);
  let shiftDurationTime = 0;

  period.forEach(item => {
    shiftDurationTime += item.sum.shift_duration;
  });
  // chart 1
  const totalShiftsLoading = shiftDurationTime;

  const legendsShiftsLoading = [
    {
      title: 'Запланированное время',
      titleColor: COLORS.DARK,
      circleColor: COLORS.BLUE,
    },
    {
      title: 'Отработано по задачам без времени',
      titleColor: COLORS.DARK,
      circleColor: COLORS.BLUE_LIGHT,
    },
    {
      title: 'Свободное время',
      titleColor: COLORS.DARK,
      circleColor: COLORS.GREY200,
    },
  ];

  const legendsShiftsLoadingDoughnut = [
    {
      title: `${convertTimeHM(total.planned, false).text}`,
      titleColor: COLORS.DARK,
      circleColor: COLORS.BLUE,
    },
    {
      title: `${convertTimeHM(total.worked_timeless, false).text}`,
      titleColor: COLORS.DARK,
      circleColor: COLORS.BLUE_LIGHT,
    },
    {
      title: `${convertTimeHM(total.free_time_planned, false).text}`,
      titleColor: COLORS.DARK,
      circleColor: COLORS.GREY200,
    },
  ];
  const dataShiftsLoadingBar = {
    labels: period.map(item =>
      getLocalTimeFormat(item.start_period_time, timeZone, FORMAT_MOMENT.DDMMYYYY),
    ),
    datasets: [
      {
        type: 'line',
        label: 'Общее доступное время в смене',
        data: period.map(item => item.sum.shift_duration),
        backgroundColor: COLORS.DARK,
        borderRadius: 4,
        stack: 'Stack 1',
      },
      {
        type: 'bar',
        label: 'Запланированное время',
        data: period.map(item => item.sum.planned),
        backgroundColor: COLORS.BLUE,
        borderRadius: 4,
        stack: 'Stack 0',
      },
      {
        type: 'bar',
        label: 'Отработано по задачам без времени',
        data: period.map(item => item.sum.worked_timeless),
        backgroundColor: COLORS.BLUE_LIGHT,
        borderRadius: 4,
        stack: 'Stack 0',
      },
      {
        type: 'bar',
        label: 'Свободное время',
        data: period.map(item => item.sum.free_time_planned),
        backgroundColor: COLORS.GREY200,
        borderRadius: 4,
        stack: 'Stack 0',
      },
    ],
  };

  const dataShiftsLoading = {
    datasets: [
      {
        data: [total.planned, total.worked_timeless, total.free_time_planned],
        backgroundColor: [COLORS.BLUE, COLORS.BLUE_LIGHT, COLORS.GREY200],
        borderWidth: 0,
      },
    ],
  };

  // chart 2
  const totalClockRatio = total.planned - (total.worked + total.overworked);

  const legendsClockRatio = [
    {
      title: 'Фактическое свободное время',
      titleColor: COLORS.DARK,
      circleColor: COLORS.GREY200,
    },
    {
      title: 'Отработано',
      titleColor: COLORS.DARK,
      circleColor: COLORS.BLUE,
    },
    {
      title: 'Отработано (БВ)',
      titleColor: COLORS.DARK,
      circleColor: COLORS.BLUE_WARMER,
    },
    {
      title: 'Переработки по задачам',
      titleColor: COLORS.DARK,
      circleColor: COLORS.ORANGE,
    },
    {
      title: 'Переработки по задачам (БВ)',
      titleColor: COLORS.DARK,
      circleColor: COLORS.YELLOW,
    },
  ];

  const legendsClockRatioDoughnut = [
    {
      title: `${convertTimeHM(total.free_time, false).text}`,
      titleColor: COLORS.DARK,
      circleColor: COLORS.GREY200,
    },
    {
      title: `${convertTimeHM(total.worked, false).text}`,
      titleColor: COLORS.DARK,
      circleColor: COLORS.BLUE,
    },
    {
      title: `${convertTimeHM(total.worked_timeless, false).text}`,
      titleColor: COLORS.DARK,
      circleColor: COLORS.BLUE_WARMER,
    },
    {
      title: `${convertTimeHM(total.overworked, false).text}`,
      titleColor: COLORS.DARK,
      circleColor: COLORS.ORANGE,
    },
    {
      title: `${convertTimeHM(total.overworked_timeless, false).text}`,
      titleColor: COLORS.DARK,
      circleColor: COLORS.YELLOW,
    },
  ];

  const dataClockRatioBar = {
    labels: period.map(item =>
      getLocalTimeFormat(item.start_period_time, timeZone, FORMAT_MOMENT.DDMMYYYY),
    ),
    datasets: [
      {
        label: 'Общедоступное время в смене',
        data: period.map(item => item.sum.shift_duration),
        backgroundColor: COLORS.DARK,
        borderRadius: 4,
        stack: 'Stack 0',
        type: 'line',
      },
      {
        label: 'Отработано',
        data: period.map(item => item.sum.worked),
        backgroundColor: COLORS.BLUE,
        borderRadius: 4,
        stack: 'Stack 1',
        type: 'bar',
      },
      {
        label: 'Отработано (БВ)',
        data: period.map(item => item.sum.worked_timeless),
        backgroundColor: COLORS.BLUE_WARMER,
        borderRadius: 4,
        stack: 'Stack 1',
        type: 'bar',
      },
      {
        label: 'Переработки по обычным задачам',
        data: period.map(item => item.sum.overworked),
        backgroundColor: COLORS.ORANGE,
        borderRadius: 4,
        stack: 'Stack 1',
        type: 'bar',
      },
      {
        label: 'Переработки по задачам БВ',
        data: period.map(item => item.sum.overworked_timeless),
        backgroundColor: COLORS.YELLOW,
        borderRadius: 4,
        stack: 'Stack 1',
        type: 'bar',
      },
      {
        label: 'Фактическое свободное время',
        data: period.map(item => (item.sum.free_time > 0 ? item.sum.free_time : 0)),
        backgroundColor: COLORS.GREY200,
        borderRadius: 4,
        stack: 'Stack 1',
        type: 'bar',
      },
    ],
  };

  const dataClockRatio = {
    datasets: [
      {
        data: [
          total.free_time,
          total.worked,
          total.worked_timeless,
          total.overworked,
          total.overworked_timeless,
        ],
        backgroundColor: [
          COLORS.GREY200,
          COLORS.BLUE,
          COLORS.BLUE_WARMER,
          COLORS.ORANGE,
          COLORS.YELLOW,
        ],
        borderWidth: 0,
      },
    ],
    tooltip: true,
  };

  // chart 3
  const dataImplementationBar = {
    labels: period.map(item =>
      getLocalTimeFormat(item.start_period_time, timeZone, FORMAT_MOMENT.DDMMYYYY),
    ),
    datasets: [
      {
        label: 'Общая продолжительность смен',
        data: period.map(item => item.sum.shift_duration),
        backgroundColor: COLORS.DARK,
        borderRadius: 4,
        stack: 'Stack 0',
        type: 'line',
      },
      {
        label: 'Фактически отработано по задачам',
        data: period.map(item => item.sum.worked_total),
        backgroundColor: COLORS.BLUE,
        borderRadius: 4,
        stack: 'Stack 1',
      },
      {
        label: 'Переработки (затрачено больше на, ч)',
        data: period.map(item => item.sum.overworked_total),
        backgroundColor: COLORS.ORANGE,
        borderRadius: 4,
        stack: 'Stack 1',
      },
      {
        label: 'Фактическое свободное время',
        data: period.map(item => item.sum.free_time),
        backgroundColor: COLORS.GREY200,
        borderRadius: 4,
        stack: 'Stack 1',
      },
      {
        label: 'Сэкономлено',
        data: period.map(item => -item.sum.underworked_total),
        backgroundColor: COLORS.GREEN,
        borderRadius: 4,
        stack: 'Stack 1',
      },
      {
        label: 'Не начато',
        data: period.map(item => -item.sum.not_started_total),
        backgroundColor: COLORS.RED,
        borderRadius: 4,
        stack: 'Stack 1',
      },
    ],
  };

  const dataImplementationBarTotal = [
    {
      color: COLORS.DARK,
      value: total.shift_duration,
    },
    {
      value: total.worked_total,
      color: COLORS.BLUE,
    },
    {
      value: total.overworked_total,
      color: COLORS.ORANGE,
    },
    {
      value: total.free_time,
      color: COLORS.GREY200,
    },
    {
      value: total.underworked_total,
      color: COLORS.GREEN,
    },
    {
      value: total.not_started_total,
      color: COLORS.RED,
    },
  ];

  return (
    <div className="layout-screen">
      <h1 className="layout-screen__title">{titlesMap.statistics}</h1>

      <SearchInput onChange={setSearch} onEnter={setSearch} title="Введите ФИО, профессию" />

      <div className="layout-screen__filter">
        <StatisticsFilters
          filterDay={filterDay}
          setFilterDay={setFilterDay}
          stepsFilter={steps}
          setStepsFilter={setSteps}
        />
        <Button
          type={BUTTON_TYPE.ICON}
          text="Скачать отчет"
          onClick={saveStat}
          className="download"
          icon
          iconType={ICON_TYPE.DOWNLOAD}
          background={COLORS.BLUE}
          disabled={loading}
        />
      </div>

      <div className="layout-screen__content">
        <div className="statisticsPage">
          <div className="statisticsPage__block">
            <div className="statisticsPage__blockTitle">Производительность</div>
            <div className="statisticsPage__blockContent">
              <div className="statisticsPage__blockTable">
                <div className="statisticsPage__title">Планирование загрузки</div>
                <div className="statisticsPage__chart">
                  <Legends items={legendsShiftsLoading} className="statisticsPage__legends" />
                  <div className="statisticsPage__chartWrapper">
                    <div className="statisticsPage__chartDoughnut">
                      <ChartDoughnut
                        data={dataShiftsLoading}
                        legends={legendsShiftsLoadingDoughnut}
                        total={`${convertTimeHM(totalShiftsLoading, false).text}`}
                      />
                    </div>
                    <div className="statisticsPage__chartBar">
                      <ChartBar data={dataShiftsLoadingBar} />
                    </div>
                  </div>
                </div>
                <div className="statisticsPage__table">
                  <div className="statisticTable">
                    <div className="statisticTable__wrapper">
                      <div className="statisticTable__inner">
                        <div className="statisticTable__header">
                          <div className="statisticTable__user">Исполнитель</div>
                          {period.map(item => (
                            <div className="statisticTable__data">
                              {getLocalTimeFormat(
                                item.start_period_time,
                                timeZone,
                                FORMAT_MOMENT.DDMMYYYY,
                              )}
                            </div>
                          ))}
                        </div>
                        {items?.map(item => (
                          <div className="statisticTable__item">
                            <div className="statisticTable__user">{item.fio}</div>
                            {item?.periods?.map((period: IShiftsStatisticItemPeriod) => (
                              <div className="statisticTable__data">
                                <span style={{ color: COLORS.GREY200 }}>
                                  {convertTimeHM(period.free_time_planned, false).text}
                                </span>
                                {' / '}
                                <span style={{ color: COLORS.BLUE }}>
                                  {convertTimeHM(period.planned, false).text}
                                </span>
                                {' / '}
                                <span style={{ color: COLORS.BLUE_LIGHT }}>
                                  {convertTimeHM(period.worked_timeless, false).text}
                                </span>
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="statisticsPage__blockContent">
              <div className="statisticsPage__blockTable">
                <div className="statisticsPage__title">Фактическая загрузка</div>
                <div className="statisticsPage__chart">
                  <Legends items={legendsClockRatio} className="statisticsPage__legends" />
                  <div className="statisticsPage__chartWrapper">
                    <div className="statisticsPage__chartDoughnut">
                      <ChartDoughnut
                        data={dataClockRatio}
                        legends={legendsClockRatioDoughnut}
                        total={
                          totalClockRatio < 0
                            ? `-${convertTimeHM(Math.abs(totalClockRatio), false).text}`
                            : `${convertTimeHM(Math.abs(totalClockRatio), false).text}`
                        }
                      />
                    </div>
                    <div className="statisticsPage__chartBar">
                      <ChartBar data={dataClockRatioBar} />
                    </div>
                  </div>
                </div>
                <div className="statisticsPage__table">
                  <div className="statisticTable">
                    <div className="statisticTable__wrapper">
                      <div className="statisticTable__inner">
                        <div className="statisticTable__header">
                          <div className="statisticTable__user">Исполнитель</div>
                          {period.map(item => (
                            <div className="statisticTable__data">
                              {getLocalTimeFormat(
                                item.start_period_time,
                                timeZone,
                                FORMAT_MOMENT.DDMMYYYY,
                              )}
                            </div>
                          ))}
                        </div>
                        {items?.map(item => (
                          <div className="statisticTable__item">
                            <div className="statisticTable__user">{item.fio}</div>
                            {item?.periods?.map((period: IShiftsStatisticItemPeriod) => (
                              <div className="statisticTable__data">
                                <span style={{ color: COLORS.GREY200 }}>
                                  {convertTimeHM(period.free_time, false).text}
                                </span>
                                {' / '}
                                <span style={{ color: COLORS.ORANGE }}>
                                  {convertTimeHM(period.overworked, false).text}
                                </span>
                                {' + '}
                                <span style={{ color: COLORS.YELLOW }}>
                                  {convertTimeHM(period.overworked_timeless, false).text}
                                </span>
                                {' / '}
                                <span style={{ color: COLORS.BLUE }}>
                                  {convertTimeHM(period.worked, false).text}
                                </span>
                                {' + '}
                                <span style={{ color: COLORS.BLUE_WARMER }}>
                                  {convertTimeHM(period.worked_timeless, false).text}
                                </span>
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="statisticsPage__blockContent">
              <div className="statisticsPage__blockTable">
                <div className="statisticsPage__title">Детализация фактической загрузки</div>
                <div className="statisticsPage__chart">
                  <div className="statisticsPage__chartWrapper">
                    <div className="statisticsPage__chartDoughnut">
                      <ChartDiagrams columns={dataImplementationBarTotal} />
                    </div>
                    <div className="statisticsPage__chartBar">
                      <ChartBar data={dataImplementationBar} />
                    </div>
                  </div>
                </div>
                <div className="statisticsPage__table">
                  <div className="statisticTable">
                    <div className="statisticTable__wrapper">
                      <div className="statisticTable__inner">
                        <div className="statisticTable__header">
                          <div className="statisticTable__user">Исполнитель</div>
                          {period.map(item => (
                            <div className="statisticTable__data">
                              {getLocalTimeFormat(
                                item.start_period_time,
                                timeZone,
                                FORMAT_MOMENT.DDMMYYYY,
                              )}
                            </div>
                          ))}
                        </div>
                        {items?.map(item => (
                          <div className="statisticTable__item">
                            <div className="statisticTable__user">{item.fio}</div>
                            {item?.periods?.map((period: IShiftsStatisticItemPeriod) => (
                              <div className="statisticTable__data">
                                <span style={{ color: COLORS.GREY200 }}>
                                  {convertTimeHM(period.free_time, false).text}
                                </span>
                                {' / '}
                                <span style={{ color: COLORS.BLUE }}>
                                  {convertTimeHM(period.worked, false).text}
                                </span>
                                {' / '}
                                <span style={{ color: COLORS.ORANGE }}>
                                  {convertTimeHM(period.overworked, false).text}
                                </span>
                                {' / '}
                                <span style={{ color: COLORS.GREEN }}>
                                  {convertTimeHM(period.underworked, false).text}
                                </span>
                                {' / '}
                                <span style={{ color: COLORS.RED }}>
                                  {convertTimeHM(period.not_done, false).text}
                                </span>
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatisticsScreen;
