import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api, {
  CommonMapLocationsList,
  ICommonMapFilter,
  ICommonMapLocationsList,
  IMapData,
  IMapLocation,
  IMapTaskLog,
  INewShift,
  ShiftsGeoData,
} from '@smena.wfm/api';
import errorHandler from '~/utils/errorHandler';

export interface IMapItem extends IMapLocation {
  geoPositions?: IMapLocation[];
  logs?: IMapTaskLog[];
  shift?: INewShift;
}

export interface IMapState {
  geoPositions: IMapItem[];
  notCheckinUsersIds: number[];
  loading: boolean;
}

const initialState: IMapState = {
  geoPositions: [],
  notCheckinUsersIds: [],
  loading: true,
};

export const getCommonMapData = createAsyncThunk(
  'getCommonMapData',
  async (input: ICommonMapFilter) => {
    const response: CommonMapLocationsList = await Api.getCommonMapData(input);
    if (response.kind === 'ok') {
      return response.data as ICommonMapLocationsList;
    } else errorHandler(response);
    return response;
  },
);

export const getShiftGeo = createAsyncThunk('getShiftGeo', async (input: number) => {
  const response: ShiftsGeoData = await Api.getShiftGeo(input);
  if (response.kind === 'ok') {
    return response.data as IMapData;
  } else errorHandler(response);
  return response;
});

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getCommonMapData.pending, state => {
      state.loading = true;
    });
    builder.addCase(getCommonMapData.rejected, state => {
      state.loading = false;
    });
    builder.addCase(getCommonMapData.fulfilled, (state, action) => {
      const payload = (action.payload as unknown) as ICommonMapLocationsList;
      state.geoPositions = payload.geoPositions;
      state.notCheckinUsersIds = payload.notCheckinUsersIds;
      state.loading = false;
    });
    builder.addCase(getShiftGeo.fulfilled, (state, action) => {
      const payload = (action.payload as unknown) as IMapData;
      const itemIndex = state.geoPositions.findIndex(
        item => item.shift_id == payload.geoPositions[0]?.shift_id,
      );
      if (!isNaN(itemIndex))
        state.geoPositions[itemIndex] = { ...state.geoPositions[itemIndex], ...action.payload };
    });
  },
});

export default mapSlice.reducer;
