import { ERepairRequestStatus, EWorkRequestStatus, EWorkRequestStatusTitle } from '@smena.wfm/api';

interface IStatus {
  title: EWorkRequestStatus | ERepairRequestStatus;
}

const Status = ({ title }: IStatus) => {
  return (
    <div className={`contentStatus contentPriority${title}`}>
      {Object.values(EWorkRequestStatusTitle)[title]}
    </div>
  );
};

export default Status;
