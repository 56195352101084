import * as React from 'react';
import moment from 'moment';
import { INewShift } from '@smena.wfm/api';
import { useSelector } from 'react-redux';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import { selectCurrentUserId } from '~/redux/selectors/authSelectors';
import { FORMAT_MOMENT } from '~/helpers/constants';

interface ITaskTrackingOnTableProps {
  users: INewShift[];
  track: IShiftTracking;
}

interface ITaskTrack {
  xPos: number;
  yPos: string;
  time: string;
  title: string;
  isCheckin?: boolean;
}

const HOUR_WIDTH = 300;

const TaskTrack = ({ xPos, yPos, time, title, isCheckin }: ITaskTrack) => {
  return (
    <div
      style={{
        position: 'absolute',
        left: xPos,
        top: yPos,
        width: HOUR_WIDTH,
        height: 20,
      }}
    >
      <div className={`${isCheckin ? 'check-track task-track' : 'task-track'}`}>
        <span className="task-track__time">{time}</span>
        <span className="task-track__title">{title}</span>
      </div>
    </div>
  );
};

export const TaskTrackingOnTable = ({ users, track }: ITaskTrackingOnTableProps) => {
  const order: number = users.findIndex(u => u.user_id === track.user_id);
  const companyOptions = useSelector(selectCompanyOptions);
  const userId = useSelector(selectCurrentUserId);

  const isMeShift = users.map(item => item.user_id).includes(userId);

  const taskTracks: ITaskTrack[] = React.useMemo(() => {
    const { begin_time, end_time, user_id } = track;
    const tracks: ITaskTrack[] = [];
    const userOrder = order !== -1;

    if (userOrder && begin_time) {
      tracks.push({
        xPos: !isMeShift
          ? order * HOUR_WIDTH
          : userId === user_id
          ? order * HOUR_WIDTH
          : order * HOUR_WIDTH + 48,
        yPos: '-23px',
        time: moment.utc(begin_time).utcOffset(companyOptions.time_zone).format(FORMAT_MOMENT.HHMM),
        title: 'Чек-ин',
        isCheckin: true,
      });
    }

    if (userOrder && end_time) {
      tracks.push({
        xPos: !isMeShift
          ? order * HOUR_WIDTH
          : userId === user_id
          ? order * HOUR_WIDTH
          : order * HOUR_WIDTH + 48,
        yPos: 'calc(100% + 1px)',
        time: moment.utc(end_time).utcOffset(companyOptions.time_zone).format(FORMAT_MOMENT.HHMM),
        title: 'Чек-аут',
        isCheckin: true,
      });
    }

    return tracks;
  }, [order, track]);

  return (
    <>
      {taskTracks.map((track, idx) => {
        return <TaskTrack {...track} key={idx} />;
      })}
    </>
  );
};
