import * as React from 'react';
import { useAppSelector } from '~/hooks/redux';
import { selectMapShift } from '~/redux/selectors/mapSelectors';
import { Polyline } from '@pbe/react-yandex-maps';
import MapPlacemark from '~/components/map/MapPlacemark';
import moment from 'moment';
import { COLORS, FORMAT_MOMENT } from '~/helpers/constants';
import { IMapLocation } from '@smena.wfm/api';
import { groupBy } from 'lodash';

interface IFC {
  shiftId: number;
  ymaps?: any;
  currentTime?: string | null;
}

const colors = [COLORS.RED, COLORS.BLUE, COLORS.GREEN, COLORS.ORANGE, COLORS.YELLOW, COLORS.PINK];

const MapCoords = ({ shiftId, ymaps, currentTime }: IFC) => {
  const shift = useAppSelector(selectMapShift(shiftId));
  const [timePlacemark, setTimePlacemark] = React.useState<IMapLocation | null>(null);

  React.useEffect(() => {
    const a = moment(currentTime, FORMAT_MOMENT.YYYYMMDD_HHMMSS).unix();
    const timeFromCoords = shift?.geoPositions?.reduce((p, c) =>
      Math.abs(moment(c.time, FORMAT_MOMENT.YYYYMMDD_HHMMSS).unix() - a) <
      Math.abs(moment(p.time, FORMAT_MOMENT.YYYYMMDD_HHMMSS).unix() - a)
        ? c
        : p,
    );

    if (timeFromCoords) setTimePlacemark(timeFromCoords);
  }, [currentTime]);

  if (!shift) return null;

  const polylineMainCoords = shift.geoPositions
    ?.filter(coord => coord.provider === 'fused')
    .map(coord => [coord.lat, coord.lng]);

  const polylineDiwoCoords = groupBy(
    shift.geoPositions?.filter(coord => coord.provider === 'diwo'),
    'diwo_device_id',
  );
  const polylineDiwoCoordsArray = Object.values(polylineDiwoCoords).map(array =>
    array.map(coord => [coord.lat, coord.lng]),
  );
  const placeMarks = shift.geoPositions?.filter(coord => coord.type !== null);

  return (
    <>
      <Polyline
        geometry={polylineMainCoords ?? []}
        options={{
          strokeColor: COLORS.WHITE,
          strokeOpacity: 0.5,
          strokeWidth: 4,
        }}
      />
      {polylineDiwoCoordsArray.map((polylineDiwoCoords, index) => (
        <Polyline
          geometry={polylineDiwoCoords ?? []}
          options={{
            strokeColor: index > colors.length - 1 ? COLORS.WHITE : colors[index],
            strokeOpacity: 0.5,
            strokeWidth: 4,
          }}
        />
      ))}

      {placeMarks?.map((placeMark, key) => (
        <MapPlacemark ymaps={ymaps} placeMark={placeMark} key={key} />
      ))}
      {timePlacemark && <MapPlacemark ymaps={ymaps} placeMark={{ ...timePlacemark, type: null }} />}
    </>
  );
};

export default MapCoords;
