import * as React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Api, { GetInitDataResult } from '@smena.wfm/api';
import {
  selectCurrentUserId,
  selectIsUserLogged,
  selectToken,
  selectUserRole,
} from '~/redux/selectors/authSelectors';
import { SideMenu, UserMenu } from '~/components';
import { getCompanyOptions } from '~/redux/modules/companyOptionsModule';
import {
  selectCompanyOptions,
  selectIsCompanyOptionsInit,
} from '~/redux/selectors/companyOptionsSelectors';
import { signOut } from '~/redux/modules/authModule';
import store from '~/redux/configureStore';
import { selectPopup, selectAlert, selectDialogPopup } from '~/redux/selectors/stateSelectors';
import { selectUser } from '~/redux/selectors/usersSelectors';
import { diffDateDaysNow } from '~/utils';
import PopupManager, { POPUPS_NAME } from '~/components/PopupManager';
import { openPopup } from '~/redux/modules/popupModule';
import PopupWrapper from '~/components/popup/PopupWrapper';
import { PASSWORD, TYPE_ROLE } from '~/helpers/constants';
import ReportsScreen from '~/containers/reports/ReportsScreen';
import LoginScreen from '~/containers/login/LoginScreen';
import StatisticsScreen from '~/containers/statistics/StatisticsScreen';
import TasksScreen from '~/containers/tasks/TasksScreen';
import ScheduleScreen from '~/containers/shedules/ScheduleScreen';
import WorkersScreen from '~/containers/workers/WorkersScreen';
import MapScreen from '~/containers/map/MapScreen';
import { urlsMap } from '~/utils/urls';
import { centrifugeConnect } from '~/centrifuge/centrifuge';
import { setCurrentTask } from '~/redux/modules/stateModule';
import Alert from '~/components/alert/Alert';
import DialogPopup from './components/dialog-popup/DialogPopup';
import DialogPopupWrapper from './components/dialog-popup/DialogPopupWrapper';

const PrivateRoute: React.FC = ({ children, ...rest }) => {
  const isSignedIn = useSelector(selectToken);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isSignedIn ? (
          children
        ) : (
          <Redirect to={{ pathname: urlsMap.login, state: { from: location } }} />
        )
      }
    />
  );
};

const Routing = () => {
  const dispatch = useDispatch();
  const popup = useSelector(selectPopup);
  const dialogPopup = useSelector(selectDialogPopup);
  const alert = useSelector(selectAlert);
  const location = useLocation();
  const isSignedIn = useSelector(selectIsUserLogged);
  const companyOptions = useSelector(selectCompanyOptions);
  const isCompanyOptionsInit = useSelector(selectIsCompanyOptionsInit);
  const currentUserId = useSelector(selectCurrentUserId);
  const token = useSelector(selectToken);
  const userRole = useSelector(selectUserRole);
  const profile = useSelector(selectUser(currentUserId));
  // Doing it here to make sure we're logged in
  Api.setToken(`Bearer ${token}`);

  React.useEffect(() => {
    if (profile?.is_temp)
      dispatch(
        openPopup({
          name: POPUPS_NAME.SET_PASSWORD_POPUP,
          isAside: false,
          data: { type: PASSWORD.SET },
        }),
      );

    if (diffDateDaysNow(profile?.last_password_change_at || '') > 364) {
      dispatch(
        openPopup({
          name: POPUPS_NAME.SET_PASSWORD_POPUP,
          isAside: false,
          data: { type: PASSWORD.FORCED_CHANGE },
        }),
      );
    }
  }, [profile]);

  React.useEffect(() => {
    preInit();
    if (isSignedIn) {
      Api.setToken(`Bearer ${token}`);
      loadData();
    }
  }, [isSignedIn]);

  React.useEffect(() => {
    if (isSignedIn && companyOptions?.id) {
      centrifugeConnect(currentUserId, companyOptions.id);
    }
  }, [isSignedIn, companyOptions?.id]);

  const preInit = () => {
    if (!isCompanyOptionsInit) {
      dispatch(getCompanyOptions());
    }

    Api.setForbiddenHandler(() => {
      dispatch(signOut());
    });
  };

  const loadData = React.useCallback(async () => {
    const initData: GetInitDataResult = await Api.getInitData();
    if (userRole === TYPE_ROLE.SUPERVISOR) {
      dispatch(setCurrentTask());
    }

    if (initData.kind === 'ok') {
      for (const [key, payload] of Object.entries(initData.data)) {
        if (key === 'dictionaries') {
          for (const [type, payload] of Object.entries(initData.data.dictionaries)) {
            store.dispatch({ type: `${type}/clear`, payload });
            store.dispatch({ type: `${type}/create`, payload });
          }
        } else if (key === 'currentShift') {
          store.dispatch({ type: `state/setCurrentShift`, payload });
        } else {
          store.dispatch({ type: `${key}/clear`, payload });
          store.dispatch({ type: `${key}/create`, payload });
        }
      }
    }
  }, []);

  React.useEffect(() => {
    if (isCompanyOptionsInit) {
      document.title = `Smena WFM: ${companyOptions?.name}`;
    }
  }, [isCompanyOptionsInit, companyOptions?.name]);

  return (
    <Switch location={location}>
      {isSignedIn ? null : <Route exact path={urlsMap.login} component={LoginScreen} />}
      {isCompanyOptionsInit && (
        <PrivateRoute>
          {location.pathname === urlsMap.index && <Redirect to={urlsMap.tasks} />}
          {location.pathname === urlsMap.login && <Redirect to={urlsMap.tasks} />}
          <SideMenu />
          <UserMenu />
          <Route exact path={urlsMap.tasks} component={TasksScreen} />
          <Route exact path={urlsMap.schedules} component={ScheduleScreen} />
          <Route exact path={urlsMap.workers} component={WorkersScreen} />
          <Route exact path={urlsMap.statistics} component={StatisticsScreen} />
          <Route exact path={urlsMap.reports} component={ReportsScreen} />
          <Route exact path={urlsMap.map} component={MapScreen} />
          {popup.isVisible && (
            <PopupWrapper isAside={popup.isAside} name={popup.name}>
              <PopupManager name={popup.name} data={popup.data} />
            </PopupWrapper>
          )}
          {dialogPopup.isVisible && dialogPopup.data && dialogPopup.data.dialogData && (
            <DialogPopupWrapper>
              <DialogPopup {...dialogPopup.data} />
            </DialogPopupWrapper>
          )}
          {alert.isVisible && <Alert alert={alert.data} />}
        </PrivateRoute>
      )}
      <Redirect to={urlsMap.login} />
    </Switch>
  );
};

export default Routing;
