import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { getPeriodShifts } from './shiftsModule';
import Api, { INewShiftsListPeriod, INewShift, INewTask, Task } from '@smena.wfm/api';
import { FORMAT_MOMENT } from '~/helpers/constants';

export interface IStateState {
  day: string;
  departmentId: number;
  departmentIds: number[];
  workShiftId: number;
  workShiftIds: number[];
  usersIdsToShow: number[];
  currentShift?: INewShift;
  currentTask?: INewTask;
  generatorPasswordPopup?: number | null;
  alert: IAlert;
}

export const setCurrentTask = createAsyncThunk('setCurrentTask', async () => {
  const response: Task = await Api.getCurrentOrNextTask();

  return response;
});

const stateSlice = createSlice({
  name: 'state',
  initialState: {
    day: moment().format(FORMAT_MOMENT.DASH_YYYYMMDD),
    departmentId: 0,
    departmentIds: [],
    workShiftId: 0,
    workShiftIds: [],
    usersIdsToShow: [],
    currentShift: undefined,
    currentTask: undefined,
    generatorPasswordPopup: null,
    alert: {
      isVisible: false,
      data: {
        title: '',
        description: '',
        name: '',
        type: '',
        data: undefined,
      },
    },
  },
  reducers: {
    nextDay(state) {
      state.day = moment(state.day).add(1, 'days').format(FORMAT_MOMENT.DASH_YYYYMMDD);
    },
    prevDay(state) {
      state.day = moment(state.day).subtract(1, 'days').format(FORMAT_MOMENT.DASH_YYYYMMDD);
    },
    setDepartmentId(state, action) {
      state.departmentId = parseInt(action.payload);
    },
    setDepartmentIds(state, action) {
      state.departmentIds = action.payload;
    },
    setWorkShiftId(state, action) {
      state.workShiftId = parseInt(action.payload);
    },
    setWorkShiftIds(state, action) {
      state.workShiftIds = action.payload;
    },
    setCurrentShift(state, action) {
      const payload = action.payload;
      state.currentShift = Array.isArray(payload) ? payload[0] : action.payload;
    },
    removeCurrentShift(state) {
      state.currentShift = undefined;
    },
    updateCurrentShift(state, action) {
      const payload = action.payload;
      state.currentShift = Array.isArray(payload) ? payload[0] : action.payload;
    },
    setCurrentTask(state, action) {
      const payload = action?.payload;
      state.currentTask = Array.isArray(payload) ? payload[0] : payload;
    },
    removeCurrentTask(state) {
      state.currentTask = undefined;
    },
    updateCurrentTask(state, action) {
      const payload = action.payload;
      state.currentTask = Array.isArray(payload) ? payload[0] : action.payload;
    },
    setDate(state, action) {
      state.day = action.payload;
    },
    showAlert(state, action) {
      state.alert.data = action.payload;
      state.alert.isVisible = true;
    },
    hideAlert(state) {
      state.alert.data = {
        title: '',
        description: '',
        name: '',
        type: '',
        data: undefined,
      };
      state.alert.isVisible = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(getPeriodShifts.fulfilled, (state: IStateState, action) => {
      const payload = action.payload as INewShiftsListPeriod;
      if (payload?.users) {
        state.usersIdsToShow = payload.users.map(user => user.id);
      }
    });
    builder.addCase(setCurrentTask.fulfilled, (state, action) => {
      const payload = action.payload;
      if (payload === undefined || payload.kind !== 'ok') return;

      state.currentTask = Array.isArray(payload.data) ? payload.data[0] : payload.data;
    });
  },
});

export const {
  nextDay,
  prevDay,
  setDepartmentId,
  setDepartmentIds,
  setWorkShiftId,
  setWorkShiftIds,
  setDate,
  showAlert,
  hideAlert,
} = stateSlice.actions;
export default stateSlice.reducer;
