import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import { INewTaskTemplate, ITaskTimelessTemplate } from '@smena.wfm/api';
import { ReactComponent as ClockSVG } from '~/assets/clock.svg';
import { Tooltip } from 'antd';
import { openPopup } from '~/redux/modules/popupModule';
import { POPUPS_NAME } from '~/components/PopupManager';
import { getLocalTimeFormat } from '~/helpers/convertToUnix';
import { FORMAT_MOMENT } from '~/helpers/constants';
import ConvertTimeHM from '~/helpers/convertTimeHM';
import { ETaskPriority } from '@smena.wfm/api';
import Priority from '~/components/priority/Priority';

interface ITemplate {
  item:
    | (INewTaskTemplate & {
        duration?: number;
        priority?: ETaskPriority;
      })
    | (ITaskTimelessTemplate & {
        begin_time?: string;
        end_time?: string;
        priority?: ETaskPriority;
      });
}

const Template = ({ item }: ITemplate) => {
  const dispatch = useDispatch();
  const companyOptions = useSelector(selectCompanyOptions);
  const isDuration = item.duration !== undefined;
  let timeStr = '';

  if (item?.begin_time && item?.end_time) {
    const endTime = getLocalTimeFormat(
      item?.end_time,
      companyOptions.time_zone,
      FORMAT_MOMENT.HHMM,
    );
    const startTime = getLocalTimeFormat(
      item?.begin_time,
      companyOptions.time_zone,
      FORMAT_MOMENT.HHMM,
    );

    timeStr = `${startTime} - ${endTime}`;
  } else if (item?.duration) {
    timeStr = ConvertTimeHM(item.duration, false, true).text;
  }

  const handleClick = React.useCallback(() => {
    dispatch(
      openPopup({
        name: POPUPS_NAME.CREATE_TASK_POPUP,
        data: {
          template: {
            ...item,
            geo: item?.geo ? item.geo.map((geo: any) => (geo?.id ? geo.id : geo)) : 0,
            is_repeat: null,
          },
          type: isDuration ? 1 : 0,
        },
      }),
    );
  }, [item]);

  return (
    <div className="template" onClick={handleClick}>
      <div className="template__header">
        <Priority id={item?.priority} />
        <p>{timeStr}</p>
        {isDuration ? null : (
          <div className="template-icon">
            <Tooltip placement="top" title="Шаблон в расписание">
              <ClockSVG />
            </Tooltip>
          </div>
        )}
      </div>
      <p className="template__title">{item?.title}</p>
    </div>
  );
};

export default Template;
