import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api, { IWorkRequestsFilter, WorkRequestReport, WorkRequestReportData } from '@smena.wfm/api';
import errorHandler from '~/utils/errorHandler';

export const fetchWorkRequestsReport = createAsyncThunk(
  'shiftsReport/fetchAll',
  async (input: IWorkRequestsFilter) => {
    const response: WorkRequestReport = await Api.getWorkRequestsReport(input);
    if (response.kind === 'ok') {
      return response.data;
    } else {
      errorHandler(response);
    }
  },
);

const initialState: WorkRequestReportData = {
  data: [],
  totalCount: 0,
  limit: 100,
  offset: 0,
};

export const shiftsReportSlice = createSlice({
  name: 'workRequestReport',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchWorkRequestsReport.fulfilled, (state, action) => {
      const payload = action.payload as WorkRequestReportData;
      if (!payload) return;
      state.data = payload.data;
      state.totalCount = payload.totalCount;
    });
  },
});

export default shiftsReportSlice.reducer;
