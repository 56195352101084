import { useSelector } from 'react-redux';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import { getLocalTimeFormat } from '~/helpers/convertToUnix';
import { DATA_INCIDENTS_TYPES, FORMAT_MOMENT } from '~/helpers/constants';
import {
  IHelmetReportItem,
  IIncidentListItem,
  IShiftReportItem,
  ITaskReportItem,
  IWorkRequestReportItem,
  IZoneEntranceDataReportItem,
} from '@smena.wfm/api';
import RowContentContainer from '~/containers/reports/list-table/rowContentContainer';

interface IRow {
  columns: {
    title: string;
    name: string;
    check: boolean;
    width?: string;
  }[];
  row: IHelmetReportItem &
    IShiftReportItem &
    ITaskReportItem &
    IIncidentListItem &
    IZoneEntranceDataReportItem &
    IWorkRequestReportItem;
  tab: string;
}

export const Row = ({ row, columns, tab }: IRow) => {
  const companyOptions = useSelector(selectCompanyOptions);
  const renderSwitch = (value: string) => {
    switch (value) {
      case 'date':
        return getLocalTimeFormat(row.date, companyOptions.time_zone, 'DD.MM.YY');
      case 'created_at':
        return getLocalTimeFormat(row.created_at, companyOptions.time_zone, 'DD.MM.YY');
      case 'user_name':
        return row.user_name;
      case 'checkin':
        if (row.checkin) {
          return getLocalTimeFormat(row.checkin, companyOptions.time_zone, FORMAT_MOMENT.HHMM);
        } else {
          return '—';
        }
      case 'checkout':
        if (row.checkout) {
          return getLocalTimeFormat(row.checkout, companyOptions.time_zone, FORMAT_MOMENT.HHMM);
        } else {
          return '—';
        }
      case 'profession':
        return row.profession;
      case 'correlation':
        return row.correlation;
      case 'user_comment':
        return row.user_comment;
      case 'need_photo_report_before_start':
        return row.need_photo_report_before_start;
      case 'need_photo_report_after_complete':
        return row.need_photo_report_after_complete;
      case 'time_deviation':
        return row.time_deviation;
      case 'real_duration':
        return row.real_duration;
      case 'duration':
        return row.duration;
      case 'author':
        return row.author;
      case 'department':
        return row.department;
      case 'working_shift':
        return row.working_shift;
      case 'title':
        return row.title;
      case 'description':
        return row.description;
      case 'begin_time':
        if (row.begin_time) {
          return getLocalTimeFormat(row.begin_time, companyOptions.time_zone, FORMAT_MOMENT.HHMM);
        } else {
          return '—';
        }
      case 'end_time':
        if (row.end_time) {
          return getLocalTimeFormat(row.end_time, companyOptions.time_zone, FORMAT_MOMENT.HHMM);
        } else {
          return '—';
        }
      case 'real_start_time':
        if (row.real_start_time) {
          return getLocalTimeFormat(
            row.real_start_time,
            companyOptions.time_zone,
            FORMAT_MOMENT.HHMM,
          );
        } else {
          return '—';
        }
      case 'real_end_time':
        if (row.real_end_time) {
          return getLocalTimeFormat(
            row.real_end_time,
            companyOptions.time_zone,
            FORMAT_MOMENT.HHMM,
          );
        } else {
          return '—';
        }
      case 'time':
        return getLocalTimeFormat(row.time, companyOptions.time_zone, FORMAT_MOMENT.HHMMSS);
      case 'zone_name':
        return row.zone_name;
      case 'helmet_info':
        return row.helmet_info;
      case 'fixed':
        return row.fixed ? 'Да' : 'Нет';
      case 'fixed_time':
        return getLocalTimeFormat(row.fixed_time, companyOptions.time_zone, FORMAT_MOMENT.HHMMSS);
      case 'status':
        return row.status;
      case 'dinner':
        return row.dinner;
      case 'tasks_amount':
        return row.tasks_amount;
      case 'tasks_duration':
        return row.tasks_duration;
      case 'tasks_real_duration':
        return row.tasks_real_duration;
      case 'not_planned':
        return row.not_planned;
      case 'real_free_time':
        return row.real_free_time;
      case 'planned':
        return row.planned;
      case 'real_planned':
        return row.real_planned;
      case 'type':
        switch (tab) {
          case 'incidents':
            return DATA_INCIDENTS_TYPES.find(item => item.slug === row.type)?.name || '';
          default:
            return row.type;
        }
      case 'class':
        return row.class;
      case 'geo_type':
        return row.geo_type;
      case 'geo_zones':
        return row.geo_zones;
      case 'geo_deviation':
        return row.geo_deviation;
      case 'supervisor_status':
        return row.supervisor_status;
      case 'supervisor_comment':
        return row.supervisor_comment;
      case 'department_status':
        return row.department_status;
      case 'department_comment':
        return row.department_comment;
      case 'event_time':
        return getLocalTimeFormat(row.event_time, companyOptions.time_zone, 'DD.MM.YYYY HH:mm');
      case 'zone':
        return row.zone;
      case 'zone_id':
        return row.zone_id;
      case 'data':
        return row.data;
      case 'fio':
        return row.fio;
      case 'shift_start_time':
        return row.shift_start_time;
      case 'classification_author_risk':
        return row.classification_author_risk;
      case 'classification_otipb_risk':
        return row.classification_otipb_risk;
      case 'closed_at':
        return getLocalTimeFormat(row.closed_at, companyOptions.time_zone, 'DD.MM.YY');
      case 'created_by':
        return row.created_by;
      case 'executors':
        return row.executors;
      case 'logs_text':
        return <div dangerouslySetInnerHTML={{ __html: row.logs_text }}></div>;
      default:
        break;
    }
  };

  return (
    <div className="list-table__row">
      {columns
        .filter(column => column.check)
        .map((column, key) => (
          <div
            style={{ minWidth: column.width, maxWidth: column.width }}
            className={`list-table__col list-table__col_${column.name}
             ${column.check && 'visible'}`}
            key={key}
          >
            <RowContentContainer>{renderSwitch(column.name)}</RowContentContainer>
          </div>
        ))}
    </div>
  );
};
