import React from 'react';
import { INewShift } from '@smena.wfm/api';
import { AddUser } from './add-user';
import { CarouselUser } from './user';
import { useSelector } from 'react-redux';
import { selectUsers } from '~/redux/selectors/usersSelectors';
import { selectIsMoreOrEqToday } from '~/redux/selectors/stateSelectors';

interface ICarouselHeader {
  shifts: Partial<INewShift>[];
}

export const CarouselHeader: React.FC<ICarouselHeader> = ({ shifts }) => {
  const users = useSelector(selectUsers);
  const canAddUser = useSelector(selectIsMoreOrEqToday);

  return (
    <header className="task-carousel__row task-carousel__row_head">
      <div className="task-carousel__col task-carousel__col_offset" />
      {shifts.map(shift => {
        const user = users.find(user => user.id === shift.user_id);

        if (user) {
          return <CarouselUser key={user?.id} {...user} shiftId={shift?.id} />;
        }
      })}
      {canAddUser && <AddUser />}
    </header>
  );
};
