import { useSelector } from 'react-redux';
import { selectCurrentWorkingShifts } from '~/redux/selectors/workingShiftsSelectors';
import { selectDay } from '~/redux/selectors/stateSelectors';
import moment from 'moment/moment';
import { FORMAT_MOMENT } from '~/helpers/constants';
import { INewWorkingShift } from '@smena.wfm/api';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';

interface IShiftConfig {
  offset: number;
  duration: number;
}

const getShiftConfig = (workShifts: INewWorkingShift[], timeZone: number): IShiftConfig => {
  let offset = 24 * 60 * 60;
  let endOfTime = 0;
  workShifts.forEach(shift => {
    const beginTime = moment(`1970-01-01 ${shift.begin_time}`).utc(true).add(timeZone, 'hours');
    const endTime = moment(`1970-01-01 ${shift.end_time}`).utc(true).add(timeZone, 'hours');

    const beginTimeUnix =
      beginTime.unix() > 24 * 60 * 60 ? beginTime.unix() - 24 * 60 * 60 : beginTime.unix();

    offset = Math.min(offset, beginTimeUnix);
    let currentEndOfTime = endTime.unix();

    if (
      moment(shift.begin_time, FORMAT_MOMENT.HHMMSS).isSameOrAfter(
        moment(shift.end_time, FORMAT_MOMENT.HHMMSS),
      )
    ) {
      currentEndOfTime += 24 * 60 * 60;
    }

    endOfTime = Math.max(endOfTime, currentEndOfTime);
  });

  return {
    offset,
    duration: endOfTime - offset,
  };
};

const createTimeline = (selectedDay: string, shifts: INewWorkingShift[], timeZone: number) => {
  const date = moment(selectedDay).clone().startOf('day');
  const beginTime = date.clone();
  const endTime = date.clone();

  const shiftConfig = getShiftConfig(shifts, timeZone);

  beginTime.add(shiftConfig.offset, 'seconds');
  endTime.add(shiftConfig.offset, 'seconds').add(shiftConfig.duration, 'seconds');

  return { beginTime: beginTime.unix(), endTime: endTime.unix() };
};

const useShiftTime = (): { beginTime: string; endTime: string } => {
  const workingShifts = useSelector(selectCurrentWorkingShifts);
  const timeZone = useSelector(selectCompanyOptions).time_zone;
  const selectedDay = useSelector(selectDay);

  const { beginTime, endTime } = createTimeline(selectedDay, workingShifts, timeZone);

  return {
    beginTime: moment.unix(beginTime).format(FORMAT_MOMENT.YYYYMMDD_HHMMSS),
    endTime: moment.unix(endTime).format(FORMAT_MOMENT.YYYYMMDD_HHMMSS),
  };
};

export default useShiftTime;
