import { createEntityAdapter, createSlice, Dictionary, EntityId } from '@reduxjs/toolkit';
import { ITaskTimelessTemplate } from '@smena.wfm/api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from '@smena.wfm/api';
import { toast } from 'react-toastify';
import { TaskTimelessTemplate } from '@smena.wfm/api';
import { ITaskTimelessTemplateCreateForm } from '@smena.wfm/api';
import { ITaskTimelessTemplateUpdateForm } from '@smena.wfm/api';
import errorHandler from '~/utils/errorHandler';
import store from '~/redux/configureStore';
import { closePopup } from '~/redux/modules/popupModule';

export const taskTimelessTemplatesAdapter = createEntityAdapter<ITaskTimelessTemplate>({
  selectId: taskTemplate => taskTemplate.id,
  sortComparer: (a, b) => b.id - a.id,
});

export interface ITaskTimelessTemplatesState {
  ids: EntityId[];
  entities: Dictionary<ITaskTimelessTemplate>;
}

export const createTaskTimelessTemplates = createAsyncThunk(
  'createTaskTimelessTemplates',
  async (input: ITaskTimelessTemplateCreateForm) => {
    const response: TaskTimelessTemplate = await Api.createTaskTimelessTemplate(input);

    return response;
  },
);

export const updateTaskTimelessTemplates = createAsyncThunk(
  'updateTaskTimelessTemplates',
  async (input: ITaskTimelessTemplateUpdateForm) => {
    const response: TaskTimelessTemplate = await Api.updateTaskTimelessTemplate(input);

    return response;
  },
);

export const removetaskTimelessTemplates = createAsyncThunk(
  'removeTaskTimelessTemplates',
  async (taskTemplateId: number) => {
    Api.deleteTaskTimelessTemplate(taskTemplateId)
      .then(result => {
        if (result.kind === 'ok') {
          store.dispatch(closePopup());
          toast.success('Шаблон без времени удален');
          return null;
        } else errorHandler(result);
      })
      .catch(result => {
        errorHandler(result);
      });
  },
);

const taskTimelessTemplatesSlice = createSlice({
  name: 'taskTimelessTemplates',
  initialState: taskTimelessTemplatesAdapter.getInitialState(),
  reducers: {
    create: taskTimelessTemplatesAdapter.setMany,
    update: taskTimelessTemplatesAdapter.upsertMany,
    remove: taskTimelessTemplatesAdapter.removeMany,
    clear: taskTimelessTemplatesAdapter.removeAll,
  },
  extraReducers: builder => {
    builder.addCase(createTaskTimelessTemplates.fulfilled, (state, action) => {
      const payload = action.payload;
      if (payload === undefined || payload.kind !== 'ok') return;

      taskTimelessTemplatesAdapter.addOne(state, payload.data);
    });
    builder.addCase(updateTaskTimelessTemplates.fulfilled, (state, action) => {
      const payload = action.payload;
      if (payload === undefined || payload.kind !== 'ok') return;

      taskTimelessTemplatesAdapter.upsertOne(state, payload.data);
    });
  },
});

export default taskTimelessTemplatesSlice.reducer;

export const { create, update, clear, remove } = taskTimelessTemplatesSlice.actions;
