import { ETaskPriority } from '@smena.wfm/api';

const PRIORITY_TEXT = {
  E_REGULAR: 'Обычный',
  E_HIGH: 'Высокий',
  E_EMERGENCY: 'Аварийный',
};

const getPriorityText = (priority: number) => {
  const type = ETaskPriority[priority] as keyof typeof ETaskPriority;

  return PRIORITY_TEXT[type];
};

export const PRIORITIES = {
  0: getPriorityText(ETaskPriority.E_REGULAR),
  1: getPriorityText(ETaskPriority.E_HIGH),
  2: getPriorityText(ETaskPriority.E_EMERGENCY),
};

export default getPriorityText;
