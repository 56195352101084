import * as React from 'react';
import { ReactComponent as CloseSvg } from '~/assets/close.svg';
import { FileInput } from '~/components';
import { toast } from 'react-toastify';
import Api, { Task, ICommentTask, ETaskStatus, IUpdateTaskStatus } from '@smena.wfm/api';
import store from '~/redux/configureStore';
import errorHandler from '~/utils/errorHandler';
interface SimplifiedEvent<T> {
  target: {
    value: T;
  };
}

interface ITaskMessage {
  handelClick: (value: boolean) => void;
  view: boolean;
  id: number;
  type: string;
}

export const TaskMessage = ({ view, handelClick, id, type }: ITaskMessage) => {
  const [commentValue, setCommentValue] = React.useState<string>('');
  const [imageValue, setImageValue] = React.useState<string[]>([]);

  const onChangeComment = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCommentValue(event.target.value);
  };

  const handleMessage = React.useCallback(async () => {
    const input: ICommentTask = {
      id: id,
      comment: commentValue,
      images: imageValue,
      lat: 0,
      lng: 0,
    };

    const response = await Api.commentTask(input);
    if (response.kind === 'ok') {
      setImageValue([]);
      setCommentValue('');
      handelClick(false);

      if (type === 'error') {
        const inputStatus: IUpdateTaskStatus = {
          id: id,
          status: ETaskStatus.E_REJECTED,
          lat: 0,
          lng: 0,
        };

        const responseStatus = await Api.updateTaskStatus(inputStatus);

        if (responseStatus.kind !== 'ok') {
          errorHandler(responseStatus);
          return;
        }

        toast.success('Сообщение отправлено');

        const initCurrentTask: Task = await Api.getCurrentOrNextTask();

        if (initCurrentTask.kind === 'ok') {
          const payload = initCurrentTask?.data;
          store.dispatch({ type: `state/setCurrentTask`, payload });
        }
      }
    }

    if (response.kind === 'bad-data') {
      errorHandler(response);
    }
  }, [imageValue, commentValue]);

  const onFileInput = (event: SimplifiedEvent<string[]>) => {
    setImageValue(event.target.value);
  };

  return (
    <div className={`task-message ${view ? 'open' : ''}`}>
      <div className="task-message__container">
        <div className="task-message__close" onClick={() => handelClick(false)}>
          <CloseSvg />
        </div>
        <div className="task-message__content">
          <div className="task-message__title">Сообщить о проблеме</div>
          <textarea
            className="textarea textarea_gray"
            value={commentValue}
            placeholder="Здесь можно написать комментарий"
            onChange={onChangeComment}
          />
          <FileInput value={imageValue} onChange={onFileInput} />
          <div className="task-message__inner">
            <div className="task-message__button button" onClick={handleMessage}>
              отправить
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
