import * as React from 'react';
import { Placemark } from '@pbe/react-yandex-maps';
import { IMapLocation } from '@smena.wfm/api';
import { useAppSelector } from '~/hooks/redux';
import { selectUser } from '~/redux/selectors/usersSelectors';

interface IMapPlacemark {
  placeMark: IMapLocation;
  withUser?: boolean;
  ymaps?: any;
  setInfoOpen?: (value: number) => void;
}

const MapPlacemark = ({ placeMark, ymaps, withUser, setInfoOpen }: IMapPlacemark) => {
  const user = useAppSelector(selectUser(placeMark.user_id));
  const userName = withUser
    ? `${user?.lastname} ${user?.firstname?.charAt(0)?.toUpperCase()}. ${user?.middlename
        ?.charAt(0)
        ?.toUpperCase()}.`
    : null;

  const iconLayout = (setInfoOpen?: (value: number) => void) => {
    if (ymaps) {
      const iconLayout = ymaps.templateLayoutFactory.createClass(
        userName
          ? `<div class="pinContainer"><span class="pinDescription">${userName}</span></div>`
          : `<div class="pinContainer"></div>`,
        {
          build: function () {
            iconLayout.superclass.build.call(this);
            const pinContainer = this.getParentElement().getElementsByClassName('pinContainer')[0];
            const setIcon = (iconType?: 'hover' | 'focus') => {
              pinContainer.style.backgroundImage = `url(${
                require(`../../assets/map/${placeMark.type ?? 'current'}/${
                  iconType ?? 'normal'
                }.svg`).default
              })`;
            };
            setIcon();

            const smallShape = {
              type: 'Rectangle',
              coordinates: [
                [-16, -16],
                [16, 16],
              ],
            };

            const bigShape = {
              type: 'Rectangle',
              coordinates: [
                [-24, -24],
                [24, 24],
              ],
            };

            this.getData().options.set('shape', this.isActive ? bigShape : smallShape);

            this.getData().geoObject.events.add(
              'mouseenter',
              () => {
                setIcon('hover');
                this.isActive = true;
                pinContainer.classList.add('active');
              },
              this,
            );

            this.getData().geoObject.events.add(
              'mouseleave',
              () => {
                setIcon();
                this.isActive = false;
                pinContainer.classList.remove('active');
              },
              this,
            );

            this.getData().geoObject.events.add(
              'click',
              () => {
                if (setInfoOpen) setInfoOpen(placeMark.shift_id);
              },
              this,
            );
          },
        },
      );

      return iconLayout;
    }
    return null;
  };

  return (
    <Placemark
      geometry={[placeMark.lat, placeMark.lng]}
      options={{
        iconLayout: iconLayout(setInfoOpen),
      }}
    />
  );
};

export default MapPlacemark;
