import { INewUser } from '@smena.wfm/api';
import { createEntityAdapter, createSlice, Dictionary, EntityId } from '@reduxjs/toolkit';
import {
  createUser,
  fetchUsers,
  fireUser,
  recoverUser,
  updateUser,
} from '~/redux/reducers/GetUsersThunk';

export const usersAdapter = createEntityAdapter<INewUser>({
  selectId: state => state.id,
});

export interface IUsersState {
  ids: EntityId[];
  entities: Dictionary<INewUser>;
  viewIds: number[];
  totalCount: number;
}

const usersSlice = createSlice({
  name: 'users',
  initialState: usersAdapter.getInitialState({
    viewIds: [0],
    totalCount: 0,
  }),
  reducers: {
    create: usersAdapter.setMany,
    update: usersAdapter.upsertMany,
    remove: usersAdapter.removeMany,
    clear: usersAdapter.removeAll,
  },
  extraReducers: builder => {
    builder
      .addCase(fetchUsers.fulfilled, (state, action) => {
        const payload = action.payload;
        if (!payload) return;

        const viewIds: number[] = [];
        payload.data.forEach(row => {
          usersAdapter.upsertOne(state, row);
          viewIds.push(row.id);
        });

        state.totalCount = payload.totalCount;
        state.viewIds = viewIds;
      })
      .addCase(fireUser.fulfilled, (state, action) => {
        const payload = action.payload;
        if (payload === undefined || payload.kind !== 'ok') return;

        usersAdapter.updateOne(state, payload.data);
        state.viewIds = state.viewIds.filter(id => id !== payload.data.id);
      })
      .addCase(recoverUser.fulfilled, (state, action) => {
        const payload = action.payload;
        if (payload === undefined || payload.kind !== 'ok') return;

        usersAdapter.updateOne(state, payload.data);
        state.viewIds = state.viewIds.filter(id => id !== payload.data.id);
      })
      .addCase(createUser.fulfilled, (state, action) => {
        const payload = action.payload;
        if (payload === undefined || payload.kind !== 'ok') return;

        usersAdapter.addOne(state, payload.data);
        state.viewIds.unshift(payload.data.id);
      });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      const payload = action.payload;
      if (payload === undefined || payload.kind !== 'ok') return;

      usersAdapter.upsertOne(state, payload.data);
    });
  },
});

export default usersSlice.reducer;

export const { create, update, remove, clear } = usersSlice.actions;
