import Api, { INewNotification } from '@smena.wfm/api';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  Dictionary,
  EntityId,
} from '@reduxjs/toolkit';
import moment from 'moment';

export const notificationsAdapter = createEntityAdapter<INewNotification>({
  selectId: notification => notification.id,
  sortComparer: (a, b) => moment(b.date).unix() - moment(a.date).unix(),
});

export interface INotificationState {
  ids: EntityId[];
  entities: Dictionary<INewNotification>;
}

export const readAllNotifications = createAsyncThunk('notifications/readAll', async () => {
  await Api.readNotifications();
  return true;
});

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: notificationsAdapter.getInitialState(),
  reducers: {
    create: notificationsAdapter.setMany,
    update: notificationsAdapter.upsertMany,
    remove: notificationsAdapter.removeMany,
    clear: notificationsAdapter.removeAll,
  },
  extraReducers: builder => {
    builder.addCase(readAllNotifications.fulfilled, state => {
      for (const id of state.ids) {
        const entity = state.entities[id];
        if (entity) entity.is_read = true;
      }
    });
  },
});

export default notificationsSlice.reducer;

export const { create, update, remove, clear } = notificationsSlice.actions;
