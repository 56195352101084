import * as React from 'react';
import convertToLocal from '~/helpers/convertToLocal';
import Api, { INewShift } from '@smena.wfm/api';
import { selectWorkingShifts } from '~/redux/selectors/workingShiftsSelectors';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import { useSelector, useDispatch } from 'react-redux';
import { getAdditionalShifts } from '~/redux/modules/additionalShiftsModule';
import { getLocalMomentTime } from '~/helpers/convertToUnix';
import { FORMAT_MOMENT } from '~/helpers/constants';
import getTextOrToday from '~/helpers/getTextOrToday';

interface IAdditionalShiftItem {
  shift: INewShift;
}

export const AdditionalShiftItem = ({ shift }: IAdditionalShiftItem) => {
  const workingShifts = useSelector(selectWorkingShifts);
  const timeZone = useSelector(selectCompanyOptions).time_zone;
  const dispatch = useDispatch();

  const [nameWorking, setNameWorking] = React.useState<string | undefined>('');
  const [date, setDate] = React.useState<string | undefined>('');
  const [timeStart, setTimeStart] = React.useState<string | undefined>('');
  const [timeEnd, setTimeEnd] = React.useState<string | undefined>('');

  React.useEffect(() => {
    workingShifts.map(item => {
      if (item.id === shift?.working_shift_id) {
        setNameWorking(item.name);
        setTimeEnd(convertToLocal(item.end_time, false, timeZone));
        setTimeStart(convertToLocal(item.begin_time, false, timeZone));
      }
    });
  }, [workingShifts, shift]);

  React.useEffect(() => {
    const text = getLocalMomentTime(shift?.start_time, timeZone).format(FORMAT_MOMENT.DDMMMM);

    setDate(getTextOrToday(shift?.start_time, text, timeZone));
  }, [shift]);

  const handleAccept = React.useCallback(async () => {
    if (shift?.id) {
      await Api.acceptAdditionalShift(shift?.id);
      dispatch(getAdditionalShifts());
    }
  }, [shift]);

  const handleCancel = React.useCallback(async () => {
    if (shift?.id) {
      await Api.cancelAdditionalShift(shift?.id);
      dispatch(getAdditionalShifts());
    }
  }, [shift]);

  return (
    <div className="extra-smena-item">
      <div className="extra-smena-item__date">{date}</div>
      <div className="extra-smena-item__smena-time">
        <p className="extra-smena-item__smena">{nameWorking}</p>·
        <p className="extra-smena-item__time">
          {timeStart} – {timeEnd}
        </p>
      </div>
      <div className="extra-smena-item__buttons">
        <div className="extra-smena-item__button button button_remove" onClick={handleCancel}>
          отклонить
        </div>
        <div className="extra-smena-item__button button button_blue" onClick={handleAccept}>
          принять
        </div>
      </div>
    </div>
  );
};
