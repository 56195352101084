import Api, { INewShift, IShiftsList, OnlyShiftsList } from '@smena.wfm/api';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  Dictionary,
  EntityId,
} from '@reduxjs/toolkit';
import errorHandler from '~/utils/errorHandler';

export const additionalShiftsAdapter = createEntityAdapter<INewShift>({
  selectId: additionalShifts => additionalShifts.id,
});

export interface IAdditionalShiftsState {
  ids: EntityId[];
  entities: Dictionary<INewShift>;
}

export const getAdditionalShifts = createAsyncThunk('getAdditionalShifts', async () => {
  const response: OnlyShiftsList = await Api.getMyAdditionalShifts();
  if (response.kind === 'ok') {
    return response.data as IShiftsList;
  } else errorHandler(response);
  return response;
});

const additionalShiftsSlice = createSlice({
  name: 'additionalShifts',
  initialState: additionalShiftsAdapter.getInitialState(),
  reducers: {
    addOne: additionalShiftsAdapter.addOne,
    create: additionalShiftsAdapter.setMany,
    update: additionalShiftsAdapter.upsertMany,
    remove: additionalShiftsAdapter.removeMany,
    clear: additionalShiftsAdapter.removeAll,
  },
  extraReducers: builder => {
    builder.addCase(getAdditionalShifts.fulfilled, (state, action) => {
      const payload = (action.payload as unknown) as IShiftsList;
      additionalShiftsAdapter.removeAll(state);
      additionalShiftsAdapter.upsertMany(state, payload.shifts);
    });
  },
});

export default additionalShiftsSlice.reducer;

export const { create, update, remove, clear } = additionalShiftsSlice.actions;
