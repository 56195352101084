import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api, { ShiftsReport, IShiftReportFilter, ShiftsReportData } from '@smena.wfm/api';
import errorHandler from '~/utils/errorHandler';

export const fetchShiftsReport = createAsyncThunk(
  'shiftsReport/fetchAll',
  async (input: IShiftReportFilter) => {
    const response: ShiftsReport = await Api.getShiftsReport(input);
    if (response.kind === 'ok') {
      return response.data;
    } else {
      errorHandler(response);
    }
  },
);

const initialState: ShiftsReportData = {
  data: [],
  totalCount: 0,
};

export const shiftsReportSlice = createSlice({
  name: 'shiftsReport',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchShiftsReport.fulfilled, (state, action) => {
      const payload = action.payload as ShiftsReportData;
      if (!payload) return;
      state.data = payload.data;
      state.totalCount = payload.totalCount;
    });
  },
});

export default shiftsReportSlice.reducer;
