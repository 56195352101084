import Popup from '~/components/popup/Popup';
import TextareasField from '~/components/form/textarea/Textarea';
import { COLORS, NAMES_FIELD } from '~/helpers/constants';
import { FileInput } from '~/components';
import { useState } from 'react';
import onInput from '~/utils/onInput';
import * as React from 'react';
import {
  IApproveWorkRequestForm,
  IChangeStatusWorkRequestForm,
  IReturnWorkRequestForm,
  IWorkRequest,
  WorkRequest,
} from '@smena.wfm/api';
import { closePopup, openPopup } from '~/redux/modules/popupModule';
import { POPUPS_NAME } from '~/components/PopupManager';
import { useDispatch } from 'react-redux';
import Button from '~/components/form/buttons/Button';
import {
  approveWorkRequest,
  cancelCloseWorkRequestPos,
  returnWorkRequestPos,
} from '~/redux/modules/workRequestsModule';
import errorHandler from '~/utils/errorHandler';
import { toast } from 'react-toastify';

type CancelCloseType = 'cancel' | 'close' | 'department' | 'reject';
interface ICancelCloseWorkRequestPopup {
  type: CancelCloseType;
  workRequest: IWorkRequest;
}

const WorkRequestPopup = ({ type, workRequest }: ICancelCloseWorkRequestPopup) => {
  const dispatch = useDispatch();
  const [state, setState] = useState<IStateForm>({
    [NAMES_FIELD.DESCRIPTION]: '',
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [, setErrors] = React.useState<{ [name: string]: string }>({});
  const handelOnInput = (value: string | boolean | number, name: string) => {
    onInput({
      value,
      name,
      state,
      setState,
      setErrors,
    });
  };
  let titleWord = 'Отменить';
  let buttonWord = 'Отменить';
  let successText = 'Отменить';

  switch (type) {
    case 'cancel':
      titleWord = 'Отменить заявку';
      buttonWord = 'Отменить';
      successText = 'Заявка отменена';
      break;
    case 'close':
      titleWord = 'Закрыть заявку';
      buttonWord = 'Закрыть';
      successText = 'Заявка закрыта';
      break;
    case 'department':
      titleWord = 'Цех выбран неверно';
      buttonWord = 'Сбросить цех';
      successText = 'Цех сброшен';
      break;
    case 'reject':
      titleWord = 'Отклонить заявку';
      buttonWord = 'Отклонить';
      successText = 'Заявка отклонена';
      break;
  }

  const success = (text: string) => {
    toast.success(text);
    dispatch(closePopup());
  };

  const handleResult = (result: WorkRequest): void => {
    if (result.kind === 'ok') {
      success(`${successText}`);
    } else errorHandler(result);
    setLoading(false);
  };

  const handleOnClose = () => {
    dispatch(
      openPopup({
        name: POPUPS_NAME.VIEW_WORK_REQUEST_POPUP,
        data: {
          workRequest: workRequest,
        },
      }),
    );
  };

  const handleOnСancelCloseWorkRequest = async () => {
    setLoading(true);
    const typeStatus = {
      cancel: {
        status: 4,
        successText: 'отменена',
      },
      close: {
        status: 3,
        successText: 'закрыта',
      },
    };
    if (type === 'cancel' || type === 'close') {
      const valueForm: IChangeStatusWorkRequestForm = {
        comment: state[NAMES_FIELD.DESCRIPTION],
        images: state[NAMES_FIELD.ATTACHMENT],
        status: typeStatus[type].status,
        id: workRequest.id,
      };
      const result: any = await dispatch(cancelCloseWorkRequestPos(valueForm));
      handleResult(result.payload);
    }
    if (type === 'department') {
      const valueForm: IReturnWorkRequestForm = {
        description: state[NAMES_FIELD.DESCRIPTION],
        images: state[NAMES_FIELD.ATTACHMENT],
        id: workRequest.id,
      };
      const result: any = await dispatch(returnWorkRequestPos(valueForm));
      handleResult(result.payload);
    }
    if (type === 'reject') {
      const valueForm: IApproveWorkRequestForm = {
        comment: state[NAMES_FIELD.DESCRIPTION],
        images: state[NAMES_FIELD.ATTACHMENT],
        id: workRequest.id,
        approve: false,
      };
      const result: any = await dispatch(approveWorkRequest(valueForm));
      handleResult(result.payload);
    }
  };

  return (
    <Popup className="cancelCloseWorkRequestPopup" onClose={handleOnClose}>
      <div className="cancelCloseWorkRequestPopup__head">
        <h2>{titleWord}</h2>
      </div>
      <div className="cancelCloseWorkRequestPopup__body">
        <div className="cancelCloseWorkRequestPopup__block">
          <div className="textarea-wrapper">
            <TextareasField
              name={NAMES_FIELD.DESCRIPTION}
              placeholder="Введите сообщение"
              defaultValue={state[NAMES_FIELD.DESCRIPTION]}
              onInput={(value, name) => handelOnInput(value, name)}
            />
          </div>
          <FileInput
            value={state[NAMES_FIELD.ATTACHMENT]}
            onChange={e => {
              setState(prevState => {
                return {
                  ...prevState,
                  [NAMES_FIELD.ATTACHMENT]: e.target.value,
                };
              });
            }}
          />
        </div>
        <div className="cancelCloseWorkRequestPopup__block cancelCloseWorkRequestPopup__buttons">
          <Button
            text={buttonWord}
            background={COLORS.BLUE}
            disabled={loading}
            onClick={handleOnСancelCloseWorkRequest}
          />
        </div>
      </div>
    </Popup>
  );
};

export default WorkRequestPopup;
