import Api, { IRepairRequestsListForm, IRepairRequest } from '@smena.wfm/api';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  Dictionary,
  EntityId,
} from '@reduxjs/toolkit';

export const repairRequestsAdapter = createEntityAdapter<IRepairRequest>({
  selectId: repairRequest => repairRequest.id,
  sortComparer: (a, b) => b.id - a.id,
});

export interface IRepairRequestsState {
  ids: EntityId[];
  entities: Dictionary<IRepairRequest>;
  isLoading: boolean;
  total_count: number;
}
export const getRepairRequests = createAsyncThunk(
  'getRepairRequests',
  async (data: IRepairRequestsListForm) => {
    return await Api.getRepairRequestsList(data);
  },
);

const repairRequestsSlice = createSlice({
  name: 'repairRequests',
  initialState: repairRequestsAdapter.getInitialState({
    isLoading: false,
    total_count: 0,
  }),
  reducers: {
    setAll: repairRequestsAdapter.setAll,
    create: repairRequestsAdapter.setMany,
    update: repairRequestsAdapter.upsertMany,
    updateOne: repairRequestsAdapter.updateOne,
    remove: repairRequestsAdapter.removeMany,
    clear: repairRequestsAdapter.removeAll,
    add: repairRequestsAdapter.upsertOne,
  },
  extraReducers: builder => {
    builder.addCase(getRepairRequests.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getRepairRequests.fulfilled, (state, action) => {
      const payload = action.payload;
      if (payload.kind === 'ok') {
        if (action.meta.arg.offset) {
          repairRequestsAdapter.upsertMany(state, payload.data.data);
        } else {
          repairRequestsAdapter.setAll(state, payload.data.data);
        }
        state.total_count = payload.data.total_count;
        state.isLoading = false;
      }
    });
    builder.addCase(getRepairRequests.rejected, state => {
      state.isLoading = false;
    });
  },
});

export default repairRequestsSlice.reducer;

export const { update, updateOne, clear, remove } = repairRequestsSlice.actions;
