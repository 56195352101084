import * as React from 'react';
import { useSelector } from 'react-redux';
import { EShiftType } from '@smena.wfm/api';
import { selectAdditionalShifts } from '~/redux/selectors/additionalShiftsSelector';
import Popup from '~/components/popup/Popup';
import AsidePopupHeader from '~/components/popup/AsidePopupHeader';
import AsidePopupBody from '~/components/popup/AsidePopupBody';
import { AdditionalShiftItem } from '~/containers/tasks/popups/AdditionalShift/AdditionalShiftItem';

const AdditionalShiftsPopup = () => {
  const additionalShift = useSelector(selectAdditionalShifts);

  return (
    <Popup aside>
      <AsidePopupHeader>
        <h2 className="aside-popup__title">Дополнительные смены</h2>
      </AsidePopupHeader>
      <AsidePopupBody>
        <>
          {additionalShift &&
            additionalShift
              .filter(item => item.type === EShiftType.ADDITIONAL)
              .map(item => <AdditionalShiftItem key={item.id} shift={item} />)}
          {!additionalShift.length && (
            <p className="notification__none">Больше дополнительных смен нет</p>
          )}
        </>
      </AsidePopupBody>
    </Popup>
  );
};

export default AdditionalShiftsPopup;
