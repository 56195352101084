import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EOrderDirection } from '@smena.wfm/api';

export interface IUsersFilterSortState {
  field: string;
  direction: EOrderDirection;
}

const initialState: IUsersFilterSortState = {
  field: '',
  direction: EOrderDirection.ASC,
};

export const usersFilterSortSlice = createSlice({
  name: 'usersFilterSort',
  initialState,
  reducers: {
    setUsersFiltersSort(state, action: PayloadAction<IUsersFilterSortState>) {
      state.field = action.payload.field;
      state.direction = action.payload.direction;
    },
  },
});

export default usersFilterSortSlice.reducer;
export const { setUsersFiltersSort } = usersFilterSortSlice.actions;
