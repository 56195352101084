import * as React from 'react';
import { FileInput } from '~/components';
import { ETaskStatus, ETaskGeoType } from '@smena.wfm/api';
import Button from '~/components/form/buttons/Button';
import { COLORS, ALERT, ALERT_TYPE } from '~/helpers/constants';
import { useDispatch } from 'react-redux';
import { showAlert } from '~/redux/modules/stateModule';

interface ITaskStart {
  id: number;
  status: number;
  need_photo_report_before_start: boolean;
  need_photo_report_after_complete: boolean;
  geo_type: ETaskGeoType | undefined;
}

interface SimplifiedEvent<T> {
  target: {
    value: T;
  };
}

export const TaskStart = ({
  id,
  status,
  need_photo_report_before_start,
  need_photo_report_after_complete,
  geo_type,
}: ITaskStart) => {
  const dispatch = useDispatch();
  const [commentValue, setCommentValue] = React.useState<string>('');
  const [imageValue, setImageValue] = React.useState<string[]>([]);

  const changeTaskStatus = React.useCallback(() => {
    const statusT = status === ETaskStatus.E_IN_PROGRESS;
    const data = {
      title: 'Подтвердите действие',
      description: statusT ? 'Завершить задачу?' : 'Начать задачу?',
      name: ALERT.START_TASK,
      type: ALERT_TYPE.DEFAULT,
      data: {
        taskId: id,
        taskStatus: statusT ? ETaskStatus.E_COMPLETE : ETaskStatus.E_IN_PROGRESS,
        taskComment: commentValue,
        taskImages: imageValue,
      },
    };

    dispatch(showAlert(data));
  }, [id, status, imageValue, commentValue]);

  const onChangeComment = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCommentValue(event.target.value);
  };

  const onFileInput = (event: SimplifiedEvent<string[]>) => {
    setImageValue(event.target.value);
  };

  return (
    <div className="task-start">
      {need_photo_report_before_start || geo_type ? (
        <div className="task-start__mobile">Задачу требуется выполнять в приложении</div>
      ) : (
        <>
          <div className="task-start__title">
            {need_photo_report_after_complete ? 'Прикпепить фотоотчет' : 'Оставить комментарий'}
          </div>
          <div className="task-start__comment">
            <textarea
              className="textarea textarea_gray"
              value={commentValue}
              placeholder="Здесь можно написать комментарий"
              onChange={onChangeComment}
            />
          </div>
          <FileInput value={imageValue} onChange={onFileInput} />
          <div className="task-start__inner">
            <Button
              text={status === ETaskStatus.E_IN_PROGRESS ? 'Завершить задачу' : 'Начать задачу'}
              onClick={changeTaskStatus}
              background={COLORS.BLUE}
            />
          </div>
        </>
      )}
    </div>
  );
};
