import Api, {
  INewShift,
  INewShiftsList,
  INewShiftsListPeriod,
  IShiftsPeriodForm,
  ShiftsList,
  ShiftsListPeriod,
  NoopResult,
  CreatedShiftsList,
  IShiftsCreateForm,
  IShiftUpdateForm,
} from '@smena.wfm/api';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  Dictionary,
  EntityId,
} from '@reduxjs/toolkit';
import { getLocalMomentTime } from '~/helpers/convertToUnix';
import { toast } from 'react-toastify';
import moment, { Moment } from 'moment';
import { FORMAT_MOMENT } from '~/helpers/constants';
import errorHandler from '~/utils/errorHandler';

export interface INewShiftModify extends INewShift {
  times?: string;
  isBefore?: boolean;
  localTime?: string;
  dinner_begin_time?: string;
  dinner_end_time?: string;
}

export const shiftsAdapter = createEntityAdapter<INewShift>({
  selectId: shifts => shifts.id,
});

export interface IShiftsState {
  ids: EntityId[];
  entities: Dictionary<INewShiftModify>;
}

export const prepareShift = (shift: INewShiftModify, timeZone: number) => {
  const localStartTime: Moment = getLocalMomentTime(shift.start_time, timeZone);
  const localEndTime: Moment = getLocalMomentTime(shift.end_time, timeZone);

  const hourStartTime = localStartTime.hours().toString().padStart(2, '0');
  const hourEndTime = localEndTime.hours().toString().padStart(2, '0');

  shift.times = `${hourStartTime}-${hourEndTime}`;
  shift.isBefore = localEndTime.isBefore(moment());
  shift.localTime = localStartTime.format(FORMAT_MOMENT.DASH_YYYYMMDD);

  return shift;
};

export const getDayShifts = createAsyncThunk('getDayShifts', async (dayStartTime: string) => {
  const response: ShiftsList = await Api.getShiftsList({
    start_time: dayStartTime,
  });
  if (response.kind === 'ok') {
    return response.data as INewShiftsList;
  } else errorHandler(response);
});

export const getPeriodShifts = createAsyncThunk(
  'getPeriodShifts',
  async (input: { data: IShiftsPeriodForm; timeZone: number }) => {
    const response: ShiftsListPeriod = await Api.getShiftsPeriod(input.data);
    if (response.kind === 'ok') {
      response.data.shifts.map((shift: INewShiftModify) => prepareShift(shift, input.timeZone));
      return response.data as INewShiftsListPeriod;
    } else errorHandler(response);
  },
);

export const removeShift = createAsyncThunk(
  'removeShift',
  async (input: { shiftId: number; name?: string }) => {
    const { shiftId, name } = input;
    Api.removeShift(shiftId)
      .then(result => {
        if (result.kind === 'ok') {
          toast.success(`У ${name ?? 'пользователя'} удалена смена`);
          return null;
        } else {
          errorHandler(result);
        }
      })
      .catch(result => {
        errorHandler(result);
      });
  },
);

export const addUserShift = createAsyncThunk(
  'addUserShift',
  async (input: { workShiftId: number; start_time: string; userId: number }) => {
    const { workShiftId, start_time, userId } = input;
    Api.addUsersToShift({
      shift_start_time: start_time,
      working_shift_id: workShiftId,
      users_ids: [userId],
    })
      .then(result => {
        if (result.kind === 'ok') {
          return result.data;
        } else errorHandler(result);
      })
      .catch(result => {
        errorHandler(result);
      });
  },
);

export const addUserRawShift = createAsyncThunk('addUserRawShift', async (input: IStateForm) => {
  const response = await Api.addUsersToShift(input as IShiftsCreateForm);
  if (response.kind === 'ok') {
    response.data.map((shift: INewShiftModify) => prepareShift(shift, input.timeZone));
    return response.data as INewShift[];
  } else errorHandler(response);
});

export const updateShift = createAsyncThunk(
  'updateShift',
  async (input: { shiftId: number; workShiftId: number; start_time: string; userId: number }) => {
    const { shiftId, workShiftId, start_time, userId } = input;
    const removeResponse: NoopResult = await Api.removeShift(shiftId);
    if (removeResponse.kind === 'ok') {
      const addResponse: CreatedShiftsList = await Api.addUsersToShift({
        shift_start_time: start_time,
        working_shift_id: workShiftId,
        users_ids: [userId],
      });
      if (addResponse.kind === 'ok') {
        return null;
      } else {
        errorHandler(addResponse);
      }
    } else errorHandler(removeResponse);
  },
);

export const updateRawShift = createAsyncThunk('updateShift', async (input: IStateForm) => {
  const response = await Api.updateShift(input as IShiftUpdateForm);
  if (response.kind === 'ok') {
    return prepareShift(response.data, input.timeZone) as INewShift;
  } else errorHandler(response);
});

const shiftsSlice = createSlice({
  name: 'shifts',
  initialState: shiftsAdapter.getInitialState(),
  reducers: {
    addOne: shiftsAdapter.addOne,
    create: shiftsAdapter.upsertMany,
    update: shiftsAdapter.upsertMany,
    remove: shiftsAdapter.removeMany,
    clear: shiftsAdapter.removeAll,
    createShifts(state, action) {
      shiftsAdapter.upsertMany(state, action);
    },
    updateShifts(state, action) {
      shiftsAdapter.upsertMany(state, action);
    },
  },
  extraReducers: builder => {
    builder.addCase(addUserRawShift.fulfilled, (state, action) => {
      const payload = (action.payload as unknown) as INewShift[];
      if (payload) {
        shiftsAdapter.upsertMany(state, payload);
      }
    });
    builder.addCase(updateRawShift.fulfilled, (state, action) => {
      const payload = (action.payload as unknown) as INewShift;
      console.log('updateRawShift', payload);
      if (payload) {
        console.log(payload);
        shiftsAdapter.upsertOne(state, payload);
      }
    });
    builder.addCase(getDayShifts.fulfilled, (state, action) => {
      const payload = action.payload as INewShiftsList;
      if (payload?.shifts) {
        shiftsAdapter.removeAll(state);
        shiftsAdapter.upsertMany(state, payload.shifts);
      }
    });
    builder.addCase(getPeriodShifts.fulfilled, (state, action) => {
      const payload = action.payload as INewShiftsListPeriod;
      if (payload?.shifts) {
        shiftsAdapter.upsertMany(state, payload.shifts);
      }
    });
  },
});

export default shiftsSlice.reducer;

export const { create, update, remove, clear } = shiftsSlice.actions;
